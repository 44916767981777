export default {
    choose_lang: "Polski",
    common: {
        email: "Email",
        pwd: "Hasło",
        send: "Send",
        format_error: "格式错误！",
        error: "Błąd",
        now: "(teraz)",
        vip_system: "System członkowski",
        first_name: "Imię",
        last_name: "Nazwisko",
        tip_first_name: "imię",
        tip_last_name: "nazwisko",
    },
    login: {
        login: "Zaloguj się",
        title: "Welcome back",
        login_tip: "Please log in to your account",
        save_pwd: "Zapisz hasło",
        free_login: "Automatyczne logowanie w ciągu 15 dni",
        forgot_pwd: "Zapomniałeś hasła",
        no_account: "Don't have an account?",
        create_account: "Sign Up",
        email_format_error: "The format of your email address is not correct.",
        pwd_error: "Incorrect password!",
        account_no_exist: "This account does not exist!",
        login_success: "Login successful",
    },
    register: {
        new_account: "Create new account",
        reg_tip: "Fill in this form to create a new account",
        confirm_pwd: "Potwierdź hasło",
        reg_account: "Create account",
        terms_and_policy:
            "By signing up, you accept and agree to our <a class='term'>{term}</a> and <a class='policy'>{policy}</a>.",
        term: "Terms of Use",
        policy: "Privacy Policy",
        have_account: "Already have an account? ",
        login: "Log in",
        name_limit: "{fieldName} nie może przekraczać {max} znaków!",
        email_invalid: "Invalid Email",
        email_no_exist: "This email does not exist",
        pwd_limit:
            "Hasło musi mieć od 8 do 20 znaków, bez znaków specjalnych, i musi zawierać co najmniej 1 cyfrę i 1 literę (bez rozróżniania wielkości liter).",
        pwd_different: "Hasła nie pasują!",
        reg_fail_server:
            "Account creation failed! The server is down for maintenance. Please try again later.",
        reg_fail_net:
            "Account creation failed! Please check your internet connection and try again.",
        reg_success: "Account created successfully ",
        check_tip: "Akceptuję warunki i zasady.",
        please_accept: "Proszę zaznaczyć, aby zaakceptować regulamin.",
        recover_pwd: "Odzyskaj hasło",
        reset: "Zresetować",
        account_presence:
            "Ten adres e-mail już istnieje, proszę się zalogować!",
        receive_newsletter: "Subskrybuj i otrzymuj nasz biuletyn.",
    },
    forget: {
        title: "Reset your password",
        tip: "Enter your email address and we'll send password reset instructions.",
        backToLogin: "Return to<a class='loginLink'>{login}</a>",
        login: "log in",
        forget_success: "We've sent you an email to reset your password!",
        send_fail_server:
            "Failed to send! The server is down for maintenance. Please try again later.",
        send_fail_net:
            "Failed to send! Please check your internet connection and try again.",
    },
    update: {
        title: "Change your password",
        tip: "Please enter your new password",
        new_pwd: "Nowe hasło",
        submit: "Send",
        update_fail_server:
            "Failed to reset! The server is down for maintenance. Please try again later.",
        update_fail_net:
            "Failed to reset! Please check your internet connection and try again.",
        update_success: "Password reset successfully",
    },
    psersonal: {
        sidebarItems: [
            "Informacje osobiste",
            "Subskrypcja",
            "Zarządzanie urządzeniami",
            "Rozliczenia",
            "Skontaktuj się z pomocą techniczną",
            "Usługi dodatkowe",
            "Funkcje subkonta",
            "Zmień hasło",
            "Koszyk",
        ],
    },
    userInfoModule: {
        edit: "edytuj",
        name: "Nazwa",
        update: "Aktualizuj",
        reg_time: "Czas rejestracji",
        updatePwd: {
            forgot: "Zapomniane",
            current_pwd: "Obecne hasło",
            current_pwd_p: "Wprowadź obecne hasło",
            new_pwd_p: "Wprowadź nowe hasło",
            confirm_pwd_p: "Powtórz nowe hasło",
            update_btn: "Aktualizuj hasło",
        },
        update_success: "Pomyślne zmieniono nazwę",
        pwd_update_success:
            "Hasło zostało pomyślnie zmienione, proszę zalogować się ponownie.",
        profile_update_success: "Pomyślne zmieniono awatar",
        destroy: "Usuń konto",
        destroy_tip: "Czy na pewno chcesz usunąć swoje konto?",
        destroy_tip2:
            "Ta operacja trwale usunie Twoje konto i powiązane z nim dane. Funkcje aktywowane na Twoim koncie będą niedostępne po pomyślnym wylogowaniu. Proszę postępować ostrożnie!",
        destroy_success: "Konto usunięte pomyślnie",
        pwd_wrong: "Błędne hasło użytkownika!",
        have_sub:
            "Nie możesz anulować subskrypcji w normalnym stanie odnowienia. Proszę najpierw wstrzymać subskrypcję!",
    },
    subscription: {
        stop_text: "Wstrzymałeś plan subskrypcji członkowskiej dla {plan}.",
        cancel_text:
            "Subskrypcja członkostwa Twojego {plan} została anulowana.",
        canceling:
            "Twój plan subskrypcji członkowskiej dla {plan} jest obecnie anulowany.",
        renew: "Wznów subskrypcję",
        renew_sucess: "Wznowienie subskrypcji powiodło się!",
        expire_date: "Data wygaśnięcia",
        status_text: "Status subskrypcji",
        leave: "Opuść",
        purchase_date: "Czas zakupu",
        end_date: "Następna płatność",
        price: "Cena",
        num: "Liczba obsługiwanych urządzeń",
        account_status: "Status konta",
        no_subscription: "Aktualnie nie masz żadnych subskrypcji, ",
        recommend: "być może zainteresuje cię poniższy kontent",
        learn_more: "Dowiedz się więcej",
        free_download: "Darmowe pobieranie",
        trial_limit: "Ograniczenia wersji próbnej",
        blocked_on: "Zablokowano na",
        trial_start: "Czas rozpoczęcia próby",
        start_subscription: "Rozpocznij subskrypcję",
        is_trial: "Obecnie jesteś w stanie próbnym",
        manage_btn: "Zarządzaj subskrypcją",
        change_payment: "Zmień dane płatności",
        payment_detail: "Szczegóły płatności",
        leave_tip: "Czy na pewno chcesz opuścić plan rodzinny?",
        leave_success: "Pomyślnie opuściłeś plan rodzinny!",
        singular_device_num:
            "{num1} Dostępne urządzenia z systemem {type}, {num2} aktywowane",
        plural_device_num:
            "{num1} Dostępne urządzenia z systemem {type}, {num2} aktywowane",
        see_detail: "Zobacz szczegóły",
        active: "Aktywny",
        pause: "Wstrzymaj",
        overdue: "(Przeterminowane)",
        update_payment_success: "Pomyślnie zmieniono metodę płatności",
        being_processed: "Przetwarzanie",
    },
    subaccount: {
        invite: "Zaprosić ludzi do dołączenia",
        send_invite: "Wyślij zaproszenie",
        manage_title: "Zarządzaj członkami rodziny",
        remove: "Usuń",
        send_success:
            "Pomyślnie wysłano e-mail z zaproszeniem, oczekuje potwierdzenia od drugiej strony!",
        remove_user: "Czy na pewno chcesz usunąć użytkownika {name}?",
        all_product: "Wszystkie produkty",
    },
    billing: {
        excl_tax: "(bez podatku od sprzedaży)",
        view_receipt: "Wyświetl paragon",
        all_time: "Cały czas",
        refunded: "Zwrócone",
    },
    manage_devices: {
        reset_all_code: "Zresetuj wszystkie urządzenia",
        reset_a_code: "Zresetuj bieżące urządzenie",
        all_reset_success: "Pomyślnie zresetowano wszystkie urządzenia!",
        a_reset_success: "Pomyślnie zresetowano {name}!",
        num_tip: "W tym roku pozostała tylko {num} szansa na reset.",
        a_warning_text: "Czy na pewno chcesz zresetować {name}?",
        all_warning_text:
            "Czy na pewno chcesz zresetować wszystkie urządzenia?",
        device_name: "Nazwa urządzenia",
        system: "System",
        reg_time: "Czas rejestracji",
        using_device: "Korzystasz z {num1} z {num2} urządzeń",
        all: "Wszystkie",
        residue_num: "Pozostałe próby resetowania",
        reset_history: "Czas historii resetowania",
        reset_fail_9: "Reset nieudany, brak pasujących danych do zresetowania",
        reset_fail_10:
            "Reset nieudany, przekroczono limit. Jeśli masz jakieś pytania, skontaktuj się z pomocą techniczną.",
    },
    contact: {
        support_center: "Centrum pomocy",
        support_tip: "Znajdź tutaj to, czego potrzebujesz",
        feedback: "Opinie",
        submit: "Prześlij",
        feedback_fail:
            "Nie udało się przesłać opinii, spróbuj ponownie później!",
        feedback_success: "Dziękujemy za opinię!",
        enter_content: "Proszę wprowadzić treść opinii",
        content_placeholder:
            "Proszę zostawić tutaj swoje sugestie lub pytania.",
        select: "Wybierz",
    },

    dm_home: {
        logout: "Wyloguj",
    },
    dm_login: {
        login_desc: "Witamy ponownie, proszę zaloguj się na swoje konto.",
        captcha_pl: "Proszę wprowadzić kod weryfikacyjny",
        or: "lub",
        reg: "Zarejestruj się",
        enter_tip: "Proszę wprowadzić swoje {fieldName}.",
        enter_email: "Proszę wprowadzić adres e-mail",
        enter_pwd: "Proszę wprowadzić hasło",
        captcha_limit: "Długość kodu weryfikacyjnego wynosi 4",
        login_expired:
            " Aby zabezpieczyć bezpieczeństwo swojego konta, musisz ponownie wprowadzić swoje hasło.",
        account_pwd_err: "Błąd konta logowania lub hasła!",
    },
    dm_reg: {
        create: "Utwórz konto",
        fill_form: "Wypełnij poniższy formularz, aby utworzyć nowe konto.",
    },
    dm_reset_pwd: {
        reset: "Odzyskaj swoje hasło",
        reset_pwd: "Zresetuj hasło",
        reset_tip:
            "Proszę wprowadzić swój adres e-mail, a my wyślemy Ci instrukcje dotyczące resetowania hasła.",
        back_login: "Powrót do logowania",
        email_error: "Adres e-mail nie istnieje",
        reset_success: "Pomyślnie zresetowano hasło.",
        email_had_sent:
            "Email do zresetowania hasła został wysłany na skrzynkę mailową, proszę zresetować swoje hasło!",
        no_sign_up:
            "Nie ma jeszcze konta dla tego adresu e-mail, proszę założyć konto.",
    },
    manageSubscription: {
        cancel_sub: "Wstrzymaj subskrypcję",
        tip: "Jeśli teraz wstrzymasz subskrypcję, nadal będziesz mógł(a) korzystać z {name} do końca obecnego cyklu rozliczeniowego.",
        cancel_success: "Subskrypcja została pomyślnie wstrzymana.",
        cancel_text:
            "Pomyślnie wstrzymałeś plan subskrypcji członkowskiej dla {productName}. Niemniej jednak, możesz nadal korzystać z {productName} do ostatniego dnia subskrypcji.",
        are_you_sure: "Czy na pewno chcesz wstrzymać subskrypcję?",
        request_success: `Twoje żądanie zostało pomyślnie wysłane; nasze centrum obsługi klienta przetworzy je w ciągu 48 godzin. Proszę sprawdzić e-mail od {officialEmail}.`,
        title: "Zarządzaj subskrypcją",
        desc: "Twój obecny plan to:",
        change_methods: "Zmień metodę subskrypcji",
        choose_system_tip: "Wybierz system urządzenia, którego potrzebujesz",
        choose_num_tip: "Wybierz liczbę urządzeń, które potrzebujesz",
        enter_num: "Proszę podać liczbę urządzeń, które potrzebujesz",
        click_enter: "Kliknij, aby wprowadzić",
        confirm: "Potwierdź",
        cancel: "Anuluj",
        choose_card_tip: "Wybierz numer karty płatniczej",
        add_card: "Dodaj numer karty",
        choose_way_tip: "Wybierz metodę subskrypcji, której potrzebujesz",
        discounts_tip: "Otrzymałeś rabat",
        total: "Razem:",
        excl_tax: "(bez podatku od sprzedaży)",
        be_counting: "Trwa obliczanie ceny",
        end_tip: "Twój termin ważności to:",
        thank_text:
            "Raz jeszcze serdecznie dziękujemy za Państwa ciągłe wsparcie. Czekamy na Państwa kolejną wizytę!",
        back: "Powrót do subskrypcji",
        update_success: "Zmiana subskrypcji udana",
        pay_btn: "Zapłać teraz",
        monthy: "Miesięczny",
        annual: "Roczny",
        life_time: "Dożywotnio",
        more: "Więcej",
        pay_tip:
            "Zmiana metody płatności: Platforma potrąci 0,01 z nowej karty, a po udanej potrąceniu można ją połączyć. Następnie kwota zostanie zwrócona na oryginalny sposób płatności.",
        is_add: "Dodaj nową kartę",
        is_set: "Ustaw nową kartę",
    },
    receipt: {
        title: "Potwierdzenie dla {name}",
        back: "Wstecz",
        receipt_text: "Potwierdzenie",
        amount_paid: "Zapłacona suma",
        date: "Data potwierdzenia",
        pay_method: "Metoda płatności",
        discount: "Rabat",
        discount_tip: "Wymienione przedmioty są zawarte w cenie",
        contact_tip:
            "Jeśli potrzebujesz pomocy z {brand}, skontaktuj się z nami",
        thank: "Dziękuję",
        item: "Produkty i Opis",
        qty: "Ilość",
        price: "Cena",
        amount: "Całkowita Kwota Wypłaty",
        subtotal: "Suma Częściowa",
        total: "RAZEM",
    },
    order_complete: {
        title: "Zamówienie zrealizowane!",
        desc: "Dziękujemy za zakup! Email z informacją o dostawie zostanie wkrótce wysłany na adres emailowy klienta.",
        order_id: "Twój numer zamówienia",
        price: "Razem zamówienie",
        name: "Nazwa produktu",
        delivery: "Dostawa",
        electronic: "Elektroniczny",
        unit_price: "Cena jednostkowa",
        quantity: "Ilość",
        final: "Cena z Rabatem",
        detail: "Szczegóły zamówienia",
        license_email: "Licencjonowany e-mail",
        license_tip:
            "Klucz licencyjny zostanie dołączony do wiadomości z dostawą.",
        contact_text:
            "Jeśli nie otrzymałeś wiadomości z dostawą, skontaktuj się z obsługą klienta:",
    },
    popup: {
        ok: "Ok",
        cancel: "Anuluj",
        confirm: "Potwierdź",
        options: ["Problemy z oprogramowaniem", "Cena", "Inne"],
    },
    add_new_card: {
        please_fill: "Proszę uzupełnić informacje",
    },
};
