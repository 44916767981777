export default {
    choose_lang: "Français",
    common: {
        email: "E-mail",
        pwd: "Mot de passe",
        send: "Envoyer",
        format_error: "格式错误！",
        error: "Erreur",
        now: "(maintenant)",
        vip_system: "Système d'adhésion",
        first_name: "Prénom",
        last_name: "Nom de famille",
        tip_first_name: "prénom",
        tip_last_name: "nom de famille",
    },
    login: {
        login: "Se connecter",
        title: "Bienvenue à nouveau",
        login_tip: "Veuillez vous connecter à votre compte",
        save_pwd: "Enregistrer le mot de passe",
        free_login: "Connexion automatique pendant 15 jours",
        forgot_pwd: "Mot de passe oublié ?",
        no_account: "Vous n'avez pas de compte ?",
        create_account: "S'inscrire.",
        email_format_error:
            "Le format de votre adresse e-mail n'est pas correct.",
        pwd_error: "Mot de passe incorrect !",
        account_no_exist: "Ce compte n'existe pas !",
        login_success: "Connexion réussie",
    },
    register: {
        new_account: "Créer un nouveau compte",
        reg_tip: "Remplissez ce formulaire pour créer un nouveau compte",
        confirm_pwd: "Confirmer le mot de passe",
        reg_account: "Créer un compte",
        terms_and_policy:
            "En vous inscrivant, vous acceptez nos <a class='term'>{term}</a> et le <a class='policy'>{policy}</a>.",
        term: "conditions d'utilisation",
        policy: "politique de confidentialité",
        have_account:
            "Le nom d'utilisateur ne doit pas dépasser 50 caractères !",
        login: "Se connecter",
        name_limit: "Le {fieldName} ne doit pas dépasser {max} caractères !",
        email_invalid: "E-mail invalide",
        email_no_exist: "Ce mail n'existe pas",
        pwd_limit:
            " Le mot de passe doit contenir entre 8 et 20 caractères, sans symboles spéciaux, et doit inclure au moins 1 chiffre et 1 lettre (indifférente à la casse).",
        pwd_different: "Les mots de passe ne correspondent pas !",
        reg_fail_server:
            "La création du compte a échoué ! Le serveur est en panne pour maintenance. Veuillez réessayer plus tard.",
        reg_fail_net:
            "La création du compte a échoué ! Veuillez vérifier votre connexion à internet et réessayez.",
        reg_success: "Compte créé avec succès",
        check_tip: "J'accepte les termes et conditions.",
        please_accept:
            "Veuillez cocher pour accepter les termes et conditions.",
        recover_pwd: "Récupérer le mot de passe",
        reset: "Réinitialiser",
        account_presence:
            "Cette adresse e-mail existe déjà, veuillez vous connecter !",
        receive_newsletter: "Abonnez-vous et recevez notre newsletter.",
    },
    forget: {
        title: "Récupérer le mot de passe",
        tip: "Entrez votre adresse e-mail et nous vous enverrons les instructions de réinitialisation du mot de passe.",
        backToLogin: "Revenir à la<a class='loginLink'>{login}</a>",
        login: "connexion",
        forget_success:
            "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe !",
        send_fail_server:
            "Échec de l'envoi ! Le serveur est en panne pour maintenance. Veuillez réessayer plus tard.",
        send_fail_net:
            "Échec de l'envoi ! Veuillez vérifier votre connexion à internet et réessayez.",
    },
    update: {
        title: "Modifier le mot de passe",
        tip: "Veuillez entrer votre nouveau mot de passe",
        new_pwd: "Nouveau mot de passe",
        submit: "Envoyer",
        update_fail_server:
            "Échec de la réinitialisation ! Le serveur est en panne pour maintenance. Veuillez réessayer plus tard.",
        update_fail_net:
            "Échec de la réinitialisation ! Veuillez vérifier votre connexion à internet et réessayez.",
        update_success: "Mot de passe réinitialisé avec succès",
    },
    psersonal: {
        sidebarItems: [
            "Informations personnelles",
            "Abonnement",
            "Gérer les appareils",
            "Facturation",
            "Contacter le support",
            "Services à valeur ajoutée",
            "Fonctionnalité de sous-compte",
            "Changer le mot de passe",
            "Panier d'achat",
        ],
    },
    userInfoModule: {
        edit: "modifier",
        name: "Nom",
        update: "Mettre à jour",
        reg_time: "Heure d'inscription",
        updatePwd: {
            forgot: "Oublié",
            current_pwd: "Mot de passe actuel",
            current_pwd_p: "Entrez le mot de passe actuel",
            new_pwd_p: "Entrez un nouveau mot de passe",
            confirm_pwd_p: "Répétez le nouveau mot de passe",
            update_btn: "Mettre à jour le mot de passe",
        },
        update_success: "Modification du nom réussie",
        pwd_update_success:
            "Mot de passe modifié avec succès, veuillez vous reconnecter.",
        profile_update_success: "Changement d'avatar réussi",
        destroy: "Supprimer le compte",
        destroy_tip: "Êtes-vous sûr de vouloir supprimer votre compte ?",
        destroy_tip2:
            "Cette opération supprimera définitivement votre compte et les données associées. Les fonctionnalités activées sous votre compte ne seront plus disponibles après une déconnexion réussie. Veuillez procéder avec prudence !",
        destroy_success: "Déconnexion du compte réussie !",
        pwd_wrong: "Mot de passe utilisateur incorrect !",
        have_sub:
            "Vous ne pouvez pas annuler un abonnement en état de renouvellement normal. Veuillez mettre en pause l'abonnement d'abord !",
    },
    subscription: {
        stop_text:
            "Vous avez suspendu le plan d'abonnement membre pour {plan}.",
        cancel_text: "L'abonnement de votre {plan} a été annulé",
        canceling:
            "Votre plan d'abonnement premium pour {plan} est actuellement en cours d'annulation.",
        renew: "Rétablir l'abonnement",
        renew_sucess: "Rétablissement de l'abonnement réussi !",
        expire_date: "Date d'expiration",
        status_text: "État de l'abonnement",
        leave: "Quitter",
        purchase_date: "Heure d'achat",
        end_date: "Prochain paiement",
        price: "Prix",
        num: "Nombre d'appareils pris en charge",
        account_status: "État du compte",
        no_subscription: "Vous n'avez actuellement aucune souscription, ",
        recommend:
            "peut-être que vous pourriez être intéressé par le contenu suivant",
        learn_more: "En savoir plus",
        free_download: "Téléchargement gratuit",
        trial_limit: "Limitations d'essai",
        blocked_on: "Bloqué sur",
        trial_start: "Heure de début de l'essai",
        start_subscription: "Démarrer l'abonnement",
        is_trial: "Vous êtes actuellement en période d'essai",
        manage_btn: "Gérer l'abonnement",
        change_payment: "Modifier les détails de paiement",
        payment_detail: "Détails de paiement",
        leave_tip: "Êtes-vous sûr de vouloir quitter le plan familial ?",
        leave_success: "Vous avez quitté avec succès le plan familial !",
        singular_device_num:
            "{num1} appareils {type} disponibles, {num2} activé",
        plural_device_num:
            "{num1} appareils {type} disponibles, {num2} activés",
        see_detail: "Voir les détails",
        active: "Actif",
        pause: "Pause",
        overdue: "(En retard)",
        update_payment_success: "Changement de mode de paiement réussi",
        being_processed: "En cours de traitement",
    },
    subaccount: {
        invite: "Inviter des personnes à rejoindre",
        send_invite: "Envoyer une invitation",
        manage_title: "Gérer les membres de la famille",
        remove: "Supprimer",
        send_success:
            "Vous avez réussi à envoyer un e-mail d'invitation, en attente de confirmation de l'autre partie !",
        remove_user:
            "Êtes-vous sûr de vouloir supprimer l'utilisateur {name} ?",
        all_product: "Tous les produits",
    },
    billing: {
        excl_tax: "(hors taxe de vente)",
        view_receipt: "Voir le reçu",
        all_time: "Toutes les périodes",
        refunded: "Remboursé",
    },
    manage_devices: {
        reset_all_code: "Réinitialiser tous les appareils",
        reset_a_code: "Réinitialiser l'appareil actuel",
        all_reset_success:
            "Vous avez réussi à réinitialiser tous les appareils !",
        a_reset_success: "Vous avez réussi à réinitialiser {name}!",
        num_tip:
            "Il ne reste qu'{num} seule chance de réinitialisation pour cette année.",
        a_warning_text: "Êtes-vous sûr de vouloir réinitialiser {name} ?",
        all_warning_text:
            "Êtes-vous sûr de vouloir réinitialiser tous les appareils ?",
        device_name: "Nom de l'appareil",
        system: "Système",
        reg_time: "Heure d'inscription",
        using_device: "Vous utilisez {num1} sur {num2} appareils",
        all: "Tout",
        residue_num: "Tentatives de réinitialisation restantes",
        reset_history: "Temps d'historique de réinitialisation",
        reset_fail_9:
            "Échec de la réinitialisation, aucune donnée correspondante à réinitialiser",
        reset_fail_10:
            "Réinitialisation échouée, dépassement de la limite. Si vous avez des questions, veuillez contacter le support.",
    },
    contact: {
        support_center: "Centre de support",
        support_tip: "Trouvez ce dont vous avez besoin ici",
        feedback: "Retour d'information",
        submit: "Soumettre",
        feedback_fail:
            "Échec de soumission des commentaires, veuillez réessayer plus tard !",
        feedback_success: "Merci pour vos commentaires !",
        enter_content: "Veuillez saisir le contenu des commentaires",
        content_placeholder:
            "Veuillez laisser vos suggestions ou questions ici.",
        select: "Sélectionner",
    },
    dm_home: {
        logout: "Déconnexion",
    },
    dm_login: {
        login_desc:
            "Bienvenue de nouveau, veuillez vous connecter à votre compte.",
        captcha_pl: "Veuillez saisir le code de vérification",
        or: "ou",
        reg: "S'inscrire",
        enter_tip: "Veuillez entrer votre {fieldName}.",
        enter_email: "Veuillez saisir votre adresse e-mail",
        enter_pwd: "Veuillez saisir votre mot de passe",
        captcha_limit:
            "La longueur du code de vérification est de 4 caractères",
        login_expired:
            "Pour protéger la sécurité de votre compte, vous devez saisir à nouveau votre mot de passe.",
        account_pwd_err: "Erreur de compte de connexion ou de mot de passe!",
    },
    dm_reg: {
        create: "Créer un compte",
        fill_form:
            "Remplissez le formulaire ci-dessous pour créer un nouveau compte.",
    },
    dm_reset_pwd: {
        reset: "Récupérer votre mot de passe",
        reset_pwd: "Réinitialiser le mot de passe",
        reset_tip:
            "Veuillez saisir votre adresse e-mail, et nous vous enverrons des instructions sur la façon de réinitialiser votre mot de passe.",
        back_login: "Retour à la connexion",
        email_error: "L'adresse e-mail n'existe pas",
        reset_success:
            "Vous avez réussi à réinitialiser votre mot de passe avec succès.",
        email_had_sent:
            "L'email de réinitialisation du mot de passe a été envoyé à la boîte de réception, veuillez réinitialiser votre mot de passe!",
        no_sign_up:
            "Il n'y a pas encore de compte pour cette adresse e-mail, veuillez vous inscrire à un compte. ",
    },
    manageSubscription: {
        title: "Gérer l'abonnement",
        desc: "Votre plan actuel est :",
        change_methods: "Changer la méthode d'abonnement",
        choose_system_tip:
            "Veuillez sélectionner le système de l'appareil dont vous avez besoin",
        choose_num_tip:
            "Veuillez sélectionner le nombre d'appareils dont vous avez besoin",
        enter_num:
            "Veuillez saisir le nombre d'appareils dont vous avez besoin",
        click_enter: "Cliquez pour entrer",
        confirm: "Confirmer",
        cancel: "Annuler",
        choose_card_tip: "Veuillez sélectionner le numéro de carte de paiement",
        add_card: "Ajouter un numéro de carte",
        choose_way_tip:
            "Veuillez sélectionner la méthode d'abonnement dont vous avez besoin",
        discounts_tip: "Vous avez bénéficié d'une réduction",
        total: "Total :",
        excl_tax: "(hors taxe de vente)",
        be_counting: "Calcul du prix en cours",
        end_tip: "Votre date d'expiration est :",
        thank_text:
            "Encore une fois, merci du fond du cœur pour votre soutien continu. Nous attendons votre prochaine visite avec impatience !",
        back: "Retour à l'abonnement",
        update_success: "Modification d'abonnement réussie",
        pay_btn: "Payer maintenant",
        monthy: "Mensuel",
        annual: "Annuel",
        life_time: "À vie",
        more: "Plus",
        pay_tip:
            "Changer le mode de paiement : La plateforme déduira 0,01 de la nouvelle carte, et une fois la déduction réussie, elle pourra être liée. Par la suite, le montant sera remboursé sur le mode de paiement d'origine.",
        is_add: "Ajouter une nouvelle carte",
        is_set: "Configurer une nouvelle carte",
        cancel_sub: "Suspendre l'abonnement",
        tip: "Si vous mettez en pause votre abonnement maintenant, vous pouvez toujours utiliser {name} jusqu'à la fin du cycle de facturation en cours.",
        cancel_success: "Pause de l'abonnement réussie.",
        cancel_text:
            "Vous avez réussi à suspendre le plan d'abonnement membre pour {productName}. Cependant, vous pouvez continuer à utiliser {productName} jusqu'au dernier jour de votre abonnement.",
        are_you_sure: "Êtes-vous sûr de vouloir suspendre l'abonnement？",
        request_success: `Votre demande a été envoyée avec succès ; notre centre de service client la traitera dans les 48 heures. Veuillez vérifier votre e-mail provenant de {officialEmail}.`,
    },
    receipt: {
        title: "Reçu pour {name}",
        back: "Retour",
        receipt_text: "Reçu",
        amount_paid: "Montant payé",
        date: "Date du reçu",
        pay_method: "Mode de paiement",
        discount: "Remise",
        discount_tip: "Les articles répertoriés sont inclus dans le prix",
        contact_tip:
            "Si vous avez besoin d'aide avec {brand}, veuillez nous contacter",
        thank: "Merci",
        item: "Articles et Description",
        qty: "Quantité",
        price: "Prix",
        amount: "Montant brut retiré",
        subtotal: "Montant brut retiré",
        total: "TOTAL",
    },
    order_complete: {
        title: "Commande complète !",
        desc: "Merci pour votre achat ! Un e-mail de livraison sera envoyé à l'adresse e-mail du client sous peu.",
        order_id: "Votre numéro de commande",
        price: "Total de la commande",
        name: "Nom du produit",
        delivery: "Livraison",
        electronic: "Électronique",
        unit_price: "Prix unitaire",
        quantity: "Quantité",
        final: "Prix réduit",
        detail: "Détails de la commande",
        license_email: "E-mail sous licence",
        license_tip:
            "La clé de licence sera incluse dans l'e-mail de livraison.",
        contact_text:
            "Si vous n'avez pas reçu l'e-mail de livraison, veuillez contacter le support client :",
    },
    popup: {
        ok: "Ok",
        cancel: "Annuler",
        confirm: "Confirmer",
        options: ["Problèmes logiciels", "Prix", "Autre"],
    },
    add_new_card: {
        please_fill: "Veuillez compléter les informations",
    },
};
