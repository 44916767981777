import request from "@/utils/request";

export function login(data) {
    return request({
        url: "/api/user/login",
        method: "post",
        data: {
            ...data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    });
}
export function register(data) {
    return request({
        url: "/api/user/register",
        method: "post",
        data: {
            ...data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    });
}
export function getCaptcha() {
    return request({
        url: "/api/user/captcha_s",
        method: "get",
    });
}
export function logout() {
    return request({
        url: `/api/user/logout`,
        method: "post",
    });
}
export function resetPwd(p, cp, other) {
    return request({
        url: `/api/user/reset/password`,
        method: "post",
        data: {
            password: p,
            password_confirmation: cp,
            token: other,
        },
    });
}
export function sendResetPwdEamil(data) {
    return request({
        url: `/api/user/send/reset/email`,
        method: "post",
        data,
    });
}
export function changePwd(data) {
    return request({
        url: "/api/user/change_pwd",
        method: "post",
        data,
    });
}
export function refreshToken() {
    return request({
        url: "/api/user/token/update",
        method: "post",
        data: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    });
}
export function destroy() {
    return request({
        url: `/api/user/destroy`,
        method: "post",
    });
}
export function jwtLogin(token) {
    return request({
        url: `/api/user/get_auth_token`,
        method: "post",
        data: {
            token,
        },
    });
}
export function googleLogin(data) {
    return request({
        url: `/api/login/google/callback`,
        method: "post",
        data,
    });
}
