<template>
    <div class="login">
        <p class="title">{{ $t("login.login") }}</p>
        <p class="text">{{ $t("dm_login.login_desc") }}</p>
        <custom-form
            ref="form"
            :formInfo="loginInfo"
            :bottomLink="{
                href: '/register',
                text: $t('dm_login.reg')
            }"
            :confirmText="$t('login.login')"
            :isLogin="true"
        >
            <template v-slot:beforeBtn>
                <el-form-item>
                    <div class="pw-operate">
                        <el-checkbox el-checkbox v-model="checked" :label="$t('login.free_login')"></el-checkbox>
                        <span class="p-a" @click="$navigateTo($event, '/reset-password')">
                            {{ $t("login.forgot_pwd") }}
                        </span>
                    </div>
                </el-form-item>
            </template>
            <template v-slot:beforeOr>
                <div class="third-party-login" v-if="vendorInfo.hasGoogleLogin">
                    <google-login :page="'login'"></google-login>
                </div>
            </template>
        </custom-form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { login } from "@/api/login.js";
import { setCookieForTime } from "@/utils/operateCookie.js";
import customForm from "../customForm.vue";
import googleLogin from "../googleLogin.vue";

export default {
    name: "loginComponent",
    components: { customForm, googleLogin },
    data() {
        return {
            loginInfo: {
                email: {
                    type: "email",
                    value: ""
                },
                password: {
                    type: "password",
                    value: ""
                }
            },
            free_login: 0,
            checked: false,
            form: null
        };
    },
    computed: {
        ...mapGetters(["vendorInfo"])
    },
    methods: {
        handleConfirm() {
            const info = this.form.getReqInfo();
            info.free_login = this.checked ? 1 : 0;
            login(info)
                .then(response => {
                    this.loginSuccess(response.data);
                })
                .catch(error => {
                    this.form.isLoading = false;
                    let errMsg = error.response?.data?.message || "error";
                    switch (errMsg) {
                        case "Login account or password error":
                            this.form.errorTip = this.$t("dm_login.account_pwd_err");
                            break;
                        case "User mailbox does not exist!":
                            this.form.errorTip = this.$t("dm_reset_pwd.email_error");
                            break;
                        default:
                            this.form.errorTip = errMsg;
                    }
                    this.form.judegeCaptcha(errMsg);
                });
        },
        loginSuccess(data) {
            setCookieForTime("_token", data.access_token, data.expires_at);
            this.form.isLoading = false;
            this.$store.commit("dmCommon/changeLoginStatus", true);
            this.$navigateTo({ text: "登录成功" }, "/personal");
            this.$store.commit("user/setUserInfo", null);
            if (sessionStorage.getItem("regInfo")) sessionStorage.removeItem("regInfo");
        },
        opCaptcha(n) {
            n == 0
                ? this.$set(this.loginInfo, "_captcha", {
                      type: "captcha",
                      value: ""
                  })
                : delete this.loginInfo._captcha;
        }
    },
    mounted() {
        if (sessionStorage.getItem("regInfo")) {
            const { email, password } = JSON.parse(sessionStorage.getItem("regInfo"));
            this.loginInfo.email.value = email;
            this.loginInfo.password.value = password;
        }

        this.form = this.$refs.form;
    }
};
</script>
