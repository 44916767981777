import request from "@/utils/request";

export function getOrderDetail(id, intent_id) {
    return request({
        url: `/api/user/order/get_detail`,
        method: "post",
        data: {
            order_no: id,
            transaction_id: intent_id,
        },
    });
}
