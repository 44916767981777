<template>
    <div class="login-right">
        <p class="title">{{ $t("update.title") }}</p>
        <p class="login-tip">{{ $t("update.tip") }}</p>
        <el-form ref="updateForm" :rules="updateRules" :model="updateInfo">
            <el-form-item prop="password">
                <el-input
                    class="input-pwd"
                    v-model="updateInfo.password"
                    :type="showPwd0 ? 'text' : 'password'"
                    :placeholder="$t('update.new_pwd')"
                    autocomplete="off"
                >
                    <eye
                        slot="suffix"
                        :isText="showPwd0"
                        :isvh="true"
                        @changeStatus="showPwd0 = !showPwd0"
                    ></eye>
                </el-input>
            </el-form-item>
            <el-form-item prop="rePassword">
                <el-input
                    class="input-pwd"
                    v-model="updateInfo.rePassword"
                    :type="showPwd1 ? 'text' : 'password'"
                    :placeholder="$t('register.confirm_pwd')"
                    autocomplete="off"
                >
                    <eye
                        slot="suffix"
                        :isText="showPwd1"
                        :isvh="true"
                        @changeStatus="showPwd1 = !showPwd1"
                    ></eye>
                </el-input>
            </el-form-item>
        </el-form>
        <el-button
            class="active"
            :style="{
                width: '390px',
            }"
            @click="handleUpdatePwd"
            >{{ $t("update.submit") }}</el-button
        >
        <p
            class="to-login"
            v-html="$t('forget.backToLogin', { login: $t('forget.login') })"
        ></p>
    </div>
</template>

<script>
import { showMessage } from "@/utils/message.js";
import eye from "@/components/eye.vue";
import { resetPwd } from "@/api/login.js";

export default {
    name: "updatePassword",
    components: { eye },
    data() {
        return {
            updateInfo: {
                password: "",
                rePassword: "",
            },
            updateRules: {
                password: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "blur",
                    },
                    {
                        min: 8,
                        max: 18,
                        message: this.$t("register.pwd_limit"),
                        trigger: "blur",
                    },
                ],
                rePassword: [{ validator: this.contrastPwd, trigger: "blur" }],
            },
            showPwd0: false,
            showPwd1: false,
        };
    },
    methods: {
        contrastPwd(rule, value, callback) {
            if (this.updateInfo.password === value) {
                callback();
            } else {
                callback(new Error(this.$t("register.pwd_different")));
            }
        },
        handleUpdatePwd() {
            resetPwd(
                this.updateInfo.password,
                this.updateInfo.rePassword,
                this.$route.params.other
            )
                .then(() => {
                    this.isLoading = false;
                    showMessage(this.$t("update.update_success"), "success");
                    this.changePage("login");
                    this.changePage("login");
                })
                .catch((err) => {
                    this.isLoading = false;
                    showMessage(
                        err.response ? err.response.data.message : err.message,
                        "success"
                    );
                });
        },
        changePage(name) {
            this.$parent.changePage(name);
        },
    },
    mounted() {
        document.querySelector(".loginLink").addEventListener("click", () => {
            this.changePage("login");
        });
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/login.scss";

::v-deep {
    .to-login {
        margin-top: 20px;
        a {
            margin-left: 4px;
            color: #3b7fff;
            cursor: pointer;
        }
    }
}
</style>
