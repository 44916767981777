export default {
    choose_lang: "한국어",

    common: {
        email: "이메일",
        pwd: "비밀번호",
        send: "Send",
        format_error: "格式错误！",
        error: "에러",
        now: "(지금)",
        vip_system: "멤버십 시스템",
        first_name: "이름",
        last_name: "성",
        tip_first_name: "이름",
        tip_last_name: "성",
    },
    login: {
        login: "로그인",
        title: "Welcome back",
        login_tip: "Please log in to your account",
        save_pwd: "비밀번호 저장",
        free_login: "15일 내 자동 로그인",
        forgot_pwd: "비밀번호를 잊으셨나요",
        no_account: "Don't have an account?",
        create_account: "Sign Up",
        email_format_error: "The format of your email address is not correct.",
        pwd_error: "Incorrect password!",
        account_no_exist: "This account does not exist!",
        login_success: "Login successful",
    },
    register: {
        new_account: "Create new account",
        reg_tip: "Fill in this form to create a new account",
        confirm_pwd: "비밀번호 확인",
        reg_account: "Create account",
        terms_and_policy:
            "By signing up, you accept and agree to our <a class='term'>{term}</a> and <a class='policy'>{policy}</a>.",
        term: "Terms of Use",
        policy: "Privacy Policy",
        have_account: "Already have an account? ",
        login: "Log in",
        name_limit: "{fieldName}은 {max}자를 초과할 수 없습니다!",
        email_invalid: "Invalid Email",
        email_no_exist: "This email does not exist",
        pwd_limit:
            "비밀번호는 8-20자 여야 하며 특수 기호 없이 1개 이상의 숫자와 1개 이상의 문자(대소문자 구분 없음)를 포함해야 합니다.",
        pwd_different: "비밀번호가 일치하지 않습니다!",
        reg_fail_server:
            "Account creation failed! The server is down for maintenance. Please try again later.",
        reg_fail_net:
            "Account creation failed! Please check your internet connection and try again.",
        reg_success: "Account created successfully ",
        check_tip: "저는 약관에 동의합니다.",
        please_accept: "약관 및 조건에 동의하려면 확인란을 선택하십시오.",
        recover_pwd: "비밀번호 찾기",
        reset: "초기화",
        account_presence: "이 이메일 주소는 이미 존재합니다. 로그인하세요!",
        receive_newsletter: "구독하고 당사의 뉴스레터를 받으세요. ",
    },
    forget: {
        title: "Reset your password",
        tip: "Enter your email address and we'll send password reset instructions.",
        backToLogin: "Return to<a class='loginLink'>{login}</a>",
        login: "log in",
        forget_success: "We've sent you an email to reset your password!",
        send_fail_server:
            "Failed to send! The server is down for maintenance. Please try again later.",
        send_fail_net:
            "Failed to send! Please check your internet connection and try again.",
    },
    update: {
        title: "Change your password",
        tip: "Please enter your new password",
        new_pwd: "새 비밀번호",
        submit: "Send",
        update_fail_server:
            "Failed to reset! The server is down for maintenance. Please try again later.",
        update_fail_net:
            "Failed to reset! Please check your internet connection and try again.",
        update_success: "Password reset successfully",
    },
    psersonal: {
        sidebarItems: [
            "개인 정보",
            "구독",
            "기기 관리",
            "청구서",
            "지원에 문의",
            "부가 서비스",
            "하위 계정 기능",
            "비밀번호 변경",
            " 장바구니",
        ],
    },
    userInfoModule: {
        edit: "편집",
        name: "이름",
        update: "업데이트",
        reg_time: "등록 시간",
        updatePwd: {
            forgot: "잊어버림",
            current_pwd: "현재 비밀번호",
            current_pwd_p: "현재 비밀번호 입력",
            new_pwd_p: "새 비밀번호 입력",
            confirm_pwd_p: "새 비밀번호 반복",
            update_btn: "비밀번호 업데이트",
        },
        update_success: "이름 수정 성공",
        pwd_update_success:
            "비밀번호가 성공적으로 변경되었습니다. 다시 로그인하세요.",
        profile_update_success: "아바타 변경 성공",
        destroy: "계정 삭제",
        destroy_tip: "계정을 삭제하시겠습니까?",
        destroy_tip2:
            "이 작업은 귀하의 계정과 관련 데이터를 영구적으로 삭제합니다. 계정에서 활성화된 기능은 로그아웃 후 사용할 수 없게 됩니다. 주의하여 진행하세요!",
        destroy_success: "계정 로그아웃 성공!",
        pwd_wrong: "사용자 비밀번호 오류!",
        have_sub:
            "정상 갱신 상태의 구독은 취소할 수 없습니다. 먼저 구독을 일시 중지하세요!",
    },
    subscription: {
        stop_text: "{plan}의 멤버십 구독 계획을 일시 중지했습니다.",
        cancel_text: "귀하의 {plan}님의 멤버십 가입이 취소되었습니다.",
        canceling: "{plan}의 멤버십 구독 계획이 현재 취소 중입니다.",
        renew: "구독 재개",
        renew_sucess: "구독이 성공적으로 재개되었습니다!",
        expire_date: "만료 날짜",
        status_text: "구독 상태",
        leave: "나가기",
        purchase_date: "구매 시간",
        end_date: "다음 결제",
        price: "가격",
        num: "지원되는 기기 수",
        account_status: "계정 상태",
        no_subscription: "현재 구독이 없습니다, ",
        recommend: "아래 내용에 관심이 있을 수 있습니다",
        learn_more: "자세히 알아보기",
        free_download: "무료 다운로드",
        trial_limit: "평가판 제한",
        blocked_on: "차단 위치",
        trial_start: "체험 시작 시간",
        start_subscription: "구독 시작",
        is_trial: "현재 체험 상태입니다",
        manage_btn: "구독 관리",
        change_payment: "결제 정보 변경",
        payment_detail: "결제 정보",
        leave_tip: "가족 요금제를 나가시겠습니까?",
        leave_success: "가족 요금제를 성공적으로 나가셨습니다!",
        singular_device_num:
            "{type} 장치 사용 가능 {num1}대, 활성화된 장치 {num2}대",
        plural_device_num:
            "{type} 장치 사용 가능 {num1}대, 활성화된 장치 {num2}대",
        see_detail: "자세히 보기",
        active: "활성",
        pause: "일시 중지",
        overdue: "(초과기한)",
        update_payment_success: "결제 방식이 성공적으로 변경되었습니다",
        being_processed: "처리 중",
    },
    subaccount: {
        invite: "사람들을 초대하여 가입하게 하기",
        send_invite: "초대 보내기",
        manage_title: "가족 구성원 관리",
        remove: "제거하기",
        send_success:
            "초대 이메일을 성공적으로 보냈습니다. 상대방의 확인을 대기 중입니다!",
        remove_user: "사용자{name}를 제거하시겠습니까?",
        all_product: "모든 제품",
    },
    billing: {
        excl_tax: "(판매세 제외)",
        view_receipt: "영수증 보기",
        all_time: "모든 시간",
        refunded: "환불 됨",
    },
    manage_devices: {
        reset_all_code: "모든 기기 초기화",
        reset_a_code: "현재 기기 초기화",
        all_reset_success: "모든 기기를 성공적으로 재설정했습니다!",
        a_reset_success: "{name}를 성공적으로 재설정!",
        num_tip: "올해 재설정 기회는 {num}회만 남았습니다.",
        a_warning_text: "{name}를 재설정하시겠습니까?",
        all_warning_text: "모든 기기를 재설정하시겠습니까?",
        device_name: "장치 이름",
        system: "시스템",
        reg_time: "등록 시간",
        using_device: "{num2}대 중 {num1}대를 사용 중입니다",
        all: "모두",
        residue_num: "남은 재설정 시도 횟수",
        reset_history: "재설정 이력 시간",
        reset_fail_9: "초기화 실패, 초기화할 데이터가 없음",
        reset_fail_10:
            "초기화에 실패했습니다. 횟수 제한을 초과했습니다. 문의 사항이 있으시면 지원팀에 문의하십시오.",
    },
    contact: {
        support_center: "지원 센터",
        support_tip: "여기에서 필요한 정보를 찾으세요",
        feedback: "피드백",
        submit: "제출",
        feedback_fail: "피드백 제출 실패, 나중에 다시 시도하세요!",
        feedback_success: "피드백 감사합니다!",
        enter_content: "피드백 내용을 입력하세요",
        content_placeholder: "제안이나 질문이 있으면 여기에 남겨 주세요.",
        select: "선택",
    },

    dm_home: {
        logout: "로그아웃",
    },
    dm_login: {
        login_desc: "다시 오신 것을 환영합니다. 계정에 로그인하세요.",
        captcha_pl: "인증 코드를 입력하세요",
        or: "또는",
        reg: "등록",
        enter_tip: "{fieldName}을 입력해주세요.",
        enter_email: "이메일 주소를 입력하세요",
        enter_pwd: "비밀번호를 입력하세요",
        captcha_limit: "인증 코드 길이는 4입니다",
        login_expired:
            "귀하의 계정 안전을 보호하기 위해 비밀번호를 다시 입력해야 합니다.",
        account_pwd_err: "로그인 계정 또는 비밀번호 오류!",
    },
    dm_reg: {
        create: "계정 만들기",
        fill_form: "새 계정을 만들려면 아래 양식을 작성하세요.",
    },
    dm_reset_pwd: {
        reset: "비밀번호 찾기",
        reset_pwd: "비밀번호 재설정",
        reset_tip:
            "이메일 주소를 입력하면 비밀번호 재설정 방법에 대한 지침을 보내드립니다.",
        back_login: "로그인으로 돌아가기",
        email_error: "이메일이 존재하지 않습니다",
        reset_success: "비밀번호를 성공적으로 재설정했습니다.",
        email_had_sent:
            "비밀번호 재설정 이메일이 메일함으로 전송되었습니다. 비밀번호를 재설정하세요!",
        no_sign_up:
            "이 이메일 주소에는 아직 계정이 없습니다. 계정을 등록하세요. ",
    },
    manageSubscription: {
        title: "구독 관리",
        desc: "현재 요금제:",
        change_methods: "구독 방법 변경",
        choose_system_tip: "필요한 장치 시스템을 선택하세요",
        choose_num_tip: "필요한 장치 수를 선택하세요",
        enter_num: "필요한 장치 수를 입력하세요",
        click_enter: "클릭하여 입력",
        confirm: "확인",
        cancel: "취소",
        choose_card_tip: "결제 카드 번호를 선택하세요",
        add_card: "카드 번호 추가",
        choose_way_tip: "필요한 구독 방법을 선택하세요",
        discounts_tip: "할인 적용 완료",
        total: "합계:",
        excl_tax: "(제외: 판매세)",
        be_counting: "가격 계산 중",
        end_tip: "만료일:",
        thank_text:
            "한 번 더 진심으로 감사드립니다. 계속된 지원에 감사드리며 다음 방문을 기대하겠습니다!",
        back: "구독으로 돌아가기",
        update_success: "구독 변경 성공",
        pay_btn: "지금 결제",
        monthy: "월간",
        annual: "연간",
        life_time: "평생",
        more: "더 보기",
        pay_tip:
            "결제 방법 변경: 플랫폼은 새 카드에서 0.01을 차감하며, 성공적인 차감 후에 연결될 수 있습니다. 이후에는 금액이 원래 결제 방법으로 환불됩니다.",
        is_add: "새로운 카드 추가",
        is_set: "새로운 카드 설정",
        cancel_sub: "구독 일시 중지",
        tip: "지금 구독을 일시 중지하면 현재 청구 주기 종료 전까지는 계속해서 {name}를 사용할 수 있습니다.",
        cancel_success: "구독 일시 중지 성공.",
        cancel_text:
            "{productName}의 멤버십 구독 계획을 성공적으로 일시 중지했습니다. 그러나 구독 멤버십의 마지막 날까지 {productName}를 계속 사용할 수 있습니다.",
        are_you_sure: "구독을 일시 중지하시겠습니까? ",
        request_success: `귀하의 요청이 성공적으로 전송되었습니다. 당사 고객 서비스 센터에서 48시간 이내에 처리될 예정입니다. {officialEmail}의 이메일을 확인해주세요.`,
    },
    receipt: {
        title: "{name}에 대한 영수증",
        back: "뒤로",
        receipt_text: "영수증",
        amount_paid: "지불된 금액",
        date: "영수증 날짜",
        pay_method: "결제 방법",
        discount: "할인",
        discount_tip: "목록에 표시된 항목은 가격에 포함되어 있습니다",
        contact_tip: "{brand}에 도움이 필요하면 저희에게 연락 주세요",
        thank: "감사합니다",
        item: "품목 및 설명",
        qty: "수량",
        price: "가격",
        amount: "총 인출 금액",
        subtotal: "소계",
        total: "총액",
    },
    order_complete: {
        title: "주문 완료!",
        desc: "구매해 주셔서 감사합니다! 고객 이메일로 곧 배송 이메일이 전송됩니다.",
        order_id: "주문 ID",
        price: "주문 총액",
        name: "제품명",
        delivery: "배송",
        electronic: "전자",
        unit_price: "단가",
        quantity: "수량",
        final: "할인된 가격",
        detail: "주문 세부정보",
        license_email: "라이선스된 이메일",
        license_tip: "라이선스 키는 배송 이메일에 포함될 것입니다.",
        contact_text: "배송 이메일을 받지 못한 경우 고객 지원에 문의하십시오:",
    },
    popup: {
        ok: "확인",
        cancel: "취소",
        confirm: "확인",
        options: ["소프트웨어 문제", "가격", "기타"],
    },
    add_new_card: {
        please_fill: "정보를 완성하십시오",
    },
};
