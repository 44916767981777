export default {
    choose_lang: "Deutsch",
    common: {
        email: "E-Mail",
        pwd: "Passwort",
        send: "senden",
        format_error: "格式错误！",
        error: "Fehler",
        now: "(jetzt)",
        vip_system: "Mitgliedersystem",
        first_name: "Vorname",
        last_name: "Nachname",
        tip_first_name: "Vornamen",
        tip_last_name: "Nachname",
    },
    login: {
        login: "Anmelden",
        title: "Willkommen zurück！",
        login_tip: "Bitte melden Sie sich bei Ihrem Konto ein.",
        save_pwd: "Mich erinnern",
        free_login: "Automatische Anmeldung innerhalb von 15 Tagen",
        forgot_pwd: "Passwort vergessen?",
        no_account: "Neu bei VideoHunter?",
        create_account: "Registrieren.",
        email_format_error: "Fehler im E-Mail-Format!",
        pwd_error: "Falsches Passwort！",
        account_no_exist: "Das Konto existiert nicht!",
        login_success: "Erfolgreich anmelden!",
    },
    register: {
        new_account: "Ein neues Konto erstellen.",
        reg_tip:
            "Füllen Sie das folgende Formular aus, um ein neues Konto zu erstellen",
        confirm_pwd: "Passwort bestätigen",
        reg_account: "Ein Konto erstellen",
        terms_and_policy:
            "Mit der Erstellung eines Kontos erkläre ich, dass ich <a class='term'>{term}</a> und <a class='policy'>{policy}</a> gelesen habe und akzeptiere.",
        term: "die Nutzungsbedingungen",
        policy: "die Datenschutzrichtlinie",
        have_account: "Sie haben schon ein Konto?",
        login: "Anmelden",
        name_limit:
            " Der {fieldName} darf maximal {max} Zeichen nicht überschreiten!",
        email_invalid: "Ungültige E-Mail",
        email_no_exist: "Die E-Mail existiert nicht!",
        pwd_limit:
            "Das Passwort muss 8-20 Zeichen lang sein, ohne Sonderzeichen, und muss mindestens 1 Zahl und 1 Buchstaben enthalten (Groß- und Kleinschreibung unwichtig).",
        pwd_different:
            "Bestätigen Sie, dass das Passwort nicht mit dem neuen Passwaort übereinstimmt.",
        reg_fail_server:
            "Erstellung ist misslungen! Die Serveranomalie wird gerade behoben. Versuchen Sie es später noch einmal.",
        reg_fail_net:
            "Erstellung ist misslungen! Bitte überprüfen Sie die Netzwerkverbindung und versuchen Sie noch einmal.",
        reg_success: "Ein Konto wurde erfolgreich erstellt.",
        check_tip: "Ich akzeptiere die Geschäftsbedingungen.",
        please_accept:
            "Bitte ankreuzen, um die Geschäftsbedingungen zu akzeptieren.",
        recover_pwd: "Passwort wiederherstellen",
        reset: "Zurücksetzen",
        account_presence:
            "Diese E-Mail-Adresse existiert bereits, bitte loggen Sie sich ein!",
        receive_newsletter:
            "Abonnieren Sie und erhalten Sie unseren Newsletter.",
    },
    forget: {
        title: "Passwort zurücksetzen",
        tip: "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen Anweisungen zum Zurücksetzen Ihres Passworts senden können.",
        backToLogin: "Zurück zum<a class='loginLink'>{login}</a>",
        login: "Anmelden",
        forget_success:
            "Die E-Mail zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet!",
        send_fail_server:
            "Das Senden ist misslungen! Die Serveranomalie wird gerade behoben. Versuchen Sie es später noch einmal.",
        send_fail_net:
            "Das Senden ist fehlgeschlagen! Bitte überprüfen Sie die Netzwerkverbindung und versuchen Sie noch einmal.",
    },
    update: {
        title: "Passwort ändern",
        tip: "Bitte fügen Sie Ihr neues Passwort ein",
        new_pwd: "Neues Passwort",
        submit: "Abgeben",
        update_fail_server:
            "Die Änderung ist misslungen Die Serveranomalie wird gerade behoben. Versuchen Sie es später noch einmal.",
        update_fail_net:
            "Die Änderung ist misslungen! Bitte überprüfen Sie die Netzwerkverbindung und versuchen Sie noch einmal.",
        update_success: "Das Passwort wurde erfolgreich geärdert.",
    },
    psersonal: {
        sidebarItems: [
            "Persönliche Informationen",
            "Abonnement",
            "Geräte verwalten",
            "Abrechnung",
            "Support kontaktieren",
            "Mehrwertdienste",
            "Funktion für Unterkonten",
            "Passwort ändern",
            "Warenkorb",
        ],
    },
    userInfoModule: {
        edit: "bearbeiten",
        name: "Name",
        update: "Aktualisieren",
        reg_time: "Anmeldezeit",
        updatePwd: {
            forgot: "Vergessen",
            current_pwd: "Aktuelles Passwort",
            current_pwd_p: "Aktuelles Passwort eingeben",
            new_pwd_p: "Neues Passwort eingeben",
            confirm_pwd_p: "Neues Passwort wiederholen",
            update_btn: "Passwort aktualisieren",
        },
        update_success: "Name erfolgreich geändert",
        pwd_update_success:
            "Passwort erfolgreich geändert, bitte melden Sie sich erneut an.",
        profile_update_success: "Avatar erfolgreich geändert",
        destroy: "Account löschen",
        destroy_tip: "Sind Sie sicher, dass Sie Ihr Konto löschen möchten?",
        destroy_tip2:
            "Diese Operation löscht Ihr Konto und die zugehörigen Daten dauerhaft. Die unter Ihrem Konto aktivierten Funktionen sind nach erfolgreichem Abmelden nicht mehr verfügbar. Bitte gehen Sie sorgfältig vor!",
        destroy_success: "Abmeldung vom Konto erfolgreich!",
        pwd_wrong: "Falsches Benutzerpasswort!",
        have_sub:
            "Sie können kein Abonnement mit normalem Verlängerungsstatus kündigen. Bitte pausieren Sie zuerst das Abonnement!",
    },
    subscription: {
        stop_text:
            "Sie haben das Mitgliedschaftsabonnement für {plan} pausiert.",
        cancel_text:
            "Das Mitgliedschaftsabonnement Ihres {plan} wurde gekündigt",
        canceling:
            "Ihr Premium-Abonnementplan für {plan} wird derzeit storniert.",
        renew: "Abonnement wiederherstellen",
        renew_sucess: "Abonnement erfolgreich wiederhergestellt!",
        expire_date: "Ablaufdatum",
        status_text: "Abonnementstatus",
        leave: "Verlassen",
        purchase_date: "Kaufzeitpunkt",
        end_date: "Nächste Zahlung",
        price: "Preis",
        num: "Unterstützte Geräteanzahl",
        account_status: "Kontostatus",
        no_subscription: "Sie haben derzeit keine Abonnements, ",
        recommend:
            "vielleicht könnten Sie an folgendem Inhalt interessiert sein",
        learn_more: "Mehr erfahren",
        free_download: "Kostenloser Download",
        trial_limit: "Einschränkungen der Testversion",
        blocked_on: "Blockiert auf",
        trial_start: "Startzeit des Testzeitraums",
        start_subscription: "Abo starten",
        is_trial: "Sie befinden sich derzeit im Teststatus",
        manage_btn: "Abo verwalten",
        change_payment: "Zahlungsdetails ändern",
        payment_detail: "Zahlungsdetails",
        leave_tip:
            "Sind Sie sicher, dass Sie den Familienplan verlassen möchten?",
        leave_success: "Sie haben den Familienplan erfolgreich verlassen!",
        singular_device_num: "{num1} {type}-Geräte verfügbar, {num2} aktiviert",
        plural_device_num: "{num1} {type}-Geräte verfügbar, {num2} aktiviert",
        see_detail: "Details anzeigen",
        active: "Aktiv",
        pause: "Pausieren",
        overdue: "(Überfällig)",
        update_payment_success: "Zahlungsmethode erfolgreich geändert",
        being_processed: "Verarbeitung läuft",
    },
    subaccount: {
        invite: "Personen einladen, beizutreten",
        send_invite: "Einladung senden",
        manage_title: "Familienmitglieder verwalten",
        remove: "Entfernen",
        send_success:
            "Sie haben erfolgreich eine Einladungs-E-Mail gesendet, die auf Bestätigung der anderen Partei wartet!",
        remove_user:
            "Sind Sie sicher, dass Sie den Benutzer {name} entfernen möchten?",
        all_product: "Alle Produkte",
    },
    billing: {
        excl_tax: "(exkl. Umsatzsteuer)",
        view_receipt: "Beleg anzeigen",
        all_time: "Die ganze Zeit",
        refunded: "Erstattet",
    },
    manage_devices: {
        reset_all_code: "Alle Geräte zurücksetzen",
        reset_a_code: "Aktuelles Gerät zurücksetzen",
        all_reset_success: "Sie haben alle Geräte erfolgreich zurückgesetzt!",
        a_reset_success: "Sie haben {name} erfolgreich zurückgesetzt!",
        num_tip:
            "Es gibt nur noch {num} verbleibende Reset-Möglichkeit für dieses Jahr.",
        a_warning_text:
            "Sind Sie sicher, dass Sie {name} zurücksetzen möchten?",
        all_warning_text:
            "Sind Sie sicher, dass Sie alle Geräte zurücksetzen möchten?",
        device_name: "Gerätename",
        system: "System",
        reg_time: "Anmeldezeit",
        using_device: "Sie verwenden {num1} von {num2} Geräten",
        all: "Alle",
        residue_num: "Verbleibende Zurücksetzungsversuche",
        reset_history: "Zurücksetzungshistorie-Zeit",
        reset_fail_9:
            "Échec de la réinitialisation, aucune donnée correspondante à réinitialiser",
        reset_fail_10:
            "Zurücksetzen fehlgeschlagen, Limit überschritten. Bei Fragen kontaktieren Sie bitte den Support.",
    },
    contact: {
        support_center: "Support-Center",
        support_tip: "Finden Sie hier, was Sie brauchen",
        feedback: "Feedback",
        submit: "Einreichen",
        feedback_fail:
            "Feedback konnte nicht gesendet werden, bitte versuchen Sie es später erneut!",
        feedback_success: "Vielen Dank für Ihr Feedback!",
        enter_content: "Bitte geben Sie den Feedback-Inhalt ein",
        content_placeholder:
            "Bitte hinterlassen Sie hier Ihre Vorschläge oder Fragen.",
        select: "Wählen",
    },
    dm_home: {
        logout: "Abmelden",
    },
    dm_login: {
        login_desc:
            "Willkommen zurück, bitte melden Sie sich in Ihrem Konto an.",
        captcha_pl: "Bitte geben Sie den Bestätigungscode ein",
        or: "oder",
        reg: "Registrieren",
        enter_tip: "Bitte geben Sie Ihren {fieldName} ein.",
        enter_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        enter_pwd: "Bitte geben Sie Ihr Passwort ein",
        captcha_limit: "Die Länge des Bestätigungscodes beträgt 4",
        login_expired:
            "Um die Sicherheit Ihres Kontos zu schützen, müssen Sie Ihr Passwort erneut eingeben.",
        account_pwd_err: "Anmeldekonto oder Passwortfehler!",
    },
    dm_reg: {
        create: "Konto erstellen",
        fill_form:
            "Füllen Sie das unten stehende Formular aus, um ein neues Konto zu erstellen.",
    },
    dm_reset_pwd: {
        reset: "Ihr Passwort wiederherstellen",
        reset_pwd: "Passwort zurücksetzen",
        reset_tip:
            "Bitte geben Sie Ihre E-Mail-Adresse ein, und wir senden Ihnen Anweisungen, wie Sie Ihr Passwort zurücksetzen können.",
        back_login: "Zurück zum Login",
        email_error: "E-Mail existiert nicht",
        reset_success: "Sie haben Ihr Passwort erfolgreich zurückgesetzt.",
        email_had_sent:
            "Die E-Mail zur Zurücksetzung des Passworts wurde an das Postfach gesendet, bitte setzen Sie Ihr Passwort zurück!",
        no_sign_up:
            "Für diese E-Mail-Adresse gibt es noch kein Konto. Bitte melden Sie sich an.",
    },
    manageSubscription: {
        title: "Abonnement verwalten",
        desc: "Ihr aktueller Plan ist:",
        change_methods: "Abo-Methode ändern",
        choose_system_tip:
            "Bitte wählen Sie das Gerätesystem aus, das Sie benötigen",
        choose_num_tip:
            "Bitte wählen Sie die Anzahl der Geräte aus, die Sie benötigen",
        enter_num:
            "Bitte geben Sie die Anzahl der Geräte ein, die Sie benötigen",
        click_enter: "Klicken Sie, um einzugeben",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        choose_card_tip: "Bitte wählen Sie die Zahlungskartennummer",
        add_card: "Kartennummer hinzufügen",
        choose_way_tip: "Bitte wählen Sie die gewünschte Abonnementmethode",
        discounts_tip: "Sie wurden rabattiert",
        total: "Gesamt:",
        excl_tax: "(exkl. Umsatzsteuer)",
        be_counting: " Preisberechnung läuft",
        end_tip: "Ihr Ablaufdatum ist:",
        thank_text:
            "Nochmals herzlichen Dank für Ihre anhaltende Unterstützung. Wir freuen uns auf Ihren nächsten Besuch!",
        back: "Zurück zum Abonnement",
        update_success: "Änderung des Abonnements erfolgreich",
        pay_btn: "Jetzt bezahlen",
        monthy: "Monatlich",
        annual: "Jährlich",
        life_time: "Lebenslang",
        more: "Mehr",
        pay_tip:
            "Zahlungsmethode ändern: Die Plattform wird 0,01 von der neuen Karte abziehen, und bei erfolgreicher Abbuchung kann sie verknüpft werden. Anschließend wird der Betrag auf dem ursprünglichen Zahlungsweg zurückerstattet.",
        is_add: "Neue Karte hinzufügen",
        is_set: "Neue Karte einrichten",
        cancel_sub: "Abonnement pausieren",
        tip: "Wenn Sie Ihr Abonnement jetzt pausieren, können Sie {name} weiterhin bis zum Ende des aktuellen Abrechnungszyklus verwenden.",
        cancel_success: "Abonnement erfolgreich pausiert.",
        cancel_text:
            "Sie haben das Mitgliedschaftsabonnement für {productName} erfolgreich pausiert. Sie können jedoch {productName} weiterhin bis zum letzten Tag Ihres Abonnements nutzen.",
        are_you_sure:
            "Sind Sie sicher, dass Sie das Abonnement pausieren möchten?",
        request_success: `Ihre Anfrage wurde erfolgreich gesendet; unser Kundenservice wird sie innerhalb von 48 Stunden bearbeiten. Bitte überprüfen Sie Ihre E-Mails von {officialEmail}.`,
    },
    receipt: {
        title: "Quittung für {name}",
        back: "Zurück",
        receipt_text: "Quittung",
        amount_paid: "Bezahlter Betrag",
        date: "Quittungsdatum",
        pay_method: "Zahlungsmethode",
        discount: "Rabatt",
        discount_tip: "Aufgeführte Artikel sind im Preis inbegriffen",
        contact_tip:
            "Wenn Sie Hilfe bei {brand} benötigen, kontaktieren Sie uns bitte",
        thank: "Danke",
        item: "Artikel & Beschreibung",
        qty: "Menge",
        price: "Preis",
        amount: "Bruttobetrag (Abhebung)",
        subtotal: "Zwischensumme",
        total: "GESAMT",
    },
    order_complete: {
        title: "Bestellung abgeschlossen!",
        desc: "Vielen Dank für Ihren Einkauf! Eine Versand-E-Mail wird in Kürze an die Kunden-E-Mail gesendet.",
        order_id: "Ihre Bestellnummer",
        price: "Gesamtsumme der Bestellung",
        name: "Produktname",
        delivery: "Lieferung",
        electronic: "Elektronisch",
        unit_price: "Stückpreis",
        quantity: "Menge",
        final: "Rabattierter Preis",
        detail: "Bestelldetails",
        license_email: "Lizenz-E-Mail",
        license_tip:
            "Der Lizenzschlüssel wird in der Liefer-E-Mail enthalten sein.",
        contact_text:
            "Wenn Sie die Liefer-E-Mail nicht erhalten haben, kontaktieren Sie bitte den Kundensupport:",
    },
    popup: {
        ok: "Ok",
        cancel: "Abbrechen",
        confirm: "Bestätigen",
        options: ["Softwareprobleme", "Preis", "Sonstiges"],
    },
    add_new_card: {
        please_fill: "Bitte vervollständigen Sie die Informationen",
    },
};
