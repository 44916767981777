<template>
    <div class="manage-subscription" ref="container">
        <div>
            <div class="main-content">
                <p class="title">{{ $t("manageSubscription.title") }}</p>
                <div class="card">
                    <div>
                        <img loading="lazy" :src="currentSubscription.icon" alt="product logo" />
                        <div>
                            <p>{{ $t("manageSubscription.desc") }}</p>
                            <p style="font-weight: 600; margin-top: 10px; line-height: 125%">
                                {{ currentSubscription.plan_name }}
                            </p>
                        </div>
                    </div>
                    <!-- <p class="p-btn cancel-btn" @click="stopSubTest"> -->
                    <p class="p-btn cancel-btn" @click="showPopup">
                        {{ $t("manageSubscription.cancel_sub") }}
                    </p>
                    <p class="tip">
                        {{
                            $t("manageSubscription.tip", {
                                name: currentSubscription.plan_name
                            })
                        }}
                    </p>
                </div>
                <p
                    v-if="false"
                    class="p-btn usable change-btn"
                    @click="toUpdateSubscription"
                    :class="isUpdateSubscription ? 'active' : ''"
                >
                    <span>{{ $t("manageSubscription.change_methods") }}</span>
                    <img loading="lazy" src="@/assets/img/icon/arrow-white.svg" alt="arrow" />
                </p>
                <div class="update-container" v-show="isUpdateSubscription">
                    <div ref="options0">
                        <p class="text">
                            {{ $t("manageSubscription.choose_system_tip") }}
                        </p>
                        <div v-if="currentSubscription.soft_platform">
                            <p
                                v-if="currentSubscription.soft_platform.includes('win')"
                                class="option-btn win-btn"
                                :class="currentSystem == 'win' ? 'active' : ''"
                                @click="chooseSystem('win')"
                            >
                                <span></span>Win{{ nowSystem == "win" ? this.$t("common.now") : "" }}
                            </p>
                            <p
                                v-if="currentSubscription.soft_platform.includes('mac')"
                                class="option-btn mac-btn"
                                :class="currentSystem == 'mac' ? 'active' : ''"
                                @click="chooseSystem('mac')"
                            >
                                <span></span>Mac{{ nowSystem == "mac" ? $t("common.now") : "" }}
                            </p>
                        </div>
                    </div>
                    <div ref="options1">
                        <p class="text">
                            {{ $t("manageSubscription.choose_num_tip") }}
                        </p>
                        <div>
                            <p
                                class="option-btn"
                                v-for="(n, index) in devicesNum"
                                :key="index"
                                :class="currentNum == n ? 'active' : ''"
                                @click="chooseNum(n, index)"
                            >
                                <span v-if="typeof n === 'number'"
                                    >{{ n }}{{ currentSystem == "win" ? "Win" : "Mac"
                                    }}{{ n == nowNum ? $t("common.now") : "" }}</span
                                >
                                <span v-else>{{ n }}</span>
                            </p>
                        </div>
                        <div class="custom-popup" v-show="isShowPopup">
                            <p class="text">
                                {{ $t("manageSubscription.enter_num") }}
                            </p>
                            <input
                                type="number"
                                onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                @input="checkE"
                                :placeholder="$t('manageSubscription.click_enter')"
                                v-model="customNum"
                            />
                            <p class="p-btn usable" @click="updateNum">
                                {{ $t("manageSubscription.confirm") }}
                            </p>
                            <p class="p-btn popup-cancel" @click="closePopup">
                                {{ $t("manageSubscription.cancel") }}
                            </p>
                        </div>
                        <choose-card
                            v-show="isChoosePayment"
                            ref="cardsComponent"
                            :showValue="isChoosePayment"
                            :source="'manage'"
                        ></choose-card>
                    </div>
                    <div ref="options2">
                        <p class="text">
                            {{ $t("manageSubscription.choose_way_tip") }}
                        </p>
                        <div>
                            <p
                                class="option-btn"
                                v-for="(t, index) in type"
                                :key="index"
                                :class="currentType == t.id ? 'active' : ''"
                                @click="currentType = t.id"
                            >
                                {{ t.name }}{{ t.id == currentSubscription.sst_id ? $t("common.now") : "" }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div class="price" v-show="!isDisabled">
                            <div v-if="priceInfo && !beGetting">
                                <p class="gray">
                                    {{ $t("manageSubscription.discounts_tip") }}{{ priceInfo.format_discount_price }}
                                </p>
                                <p class="final-price">
                                    {{ $t("manageSubscription.total") }}{{ priceInfo.format_final_price }}
                                </p>
                                <p class="gray">
                                    {{ $t("manageSubscription.excl_tax") }}
                                </p>
                            </div>
                            <div v-else class="loading-box">
                                <p class="loading"></p>
                                <p>
                                    {{ $t("manageSubscription.be_counting") }}
                                </p>
                            </div>
                        </div>
                        <el-button
                            class="p-btn pay-btn"
                            :class="isDisabled ? 'disabled' : ''"
                            @click="handleChangeSubscribe"
                            v-loading="isLoading"
                            >{{ isLoading ? "" : $t("manageSubscription.pay_btn") }}</el-button
                        >
                    </div>
                </div>
            </div>
            <span class="close-btn" @click="$navigateTo($event, '/personal')"></span>
            <popup
                ref="stopSubPopup"
                :isSuccess="isSuccess"
                :isWarning="isWarning"
                :isError="isError"
                :text="popupText"
                :isWaiting="isWaiting"
                :isStopSub="isStopSub"
            ></popup>
            <!-- <div class="cancel-content">
                <p class="title">
                    {{ $t("manageSubscription.cancel_success") }}
                </p>
                <div class="text">
                    <p>
                        {{
                            $t("manageSubscription.cancel_text", {
                                productName: "Video Converter",
                            })
                        }}
                    </p>
                    <p>
                        {{ $t("manageSubscription.end_tip") }}
                        <span>{{ currentSubscription.subscribe_end_at }}</span>
                    </p>
                    <p>{{ $t("manageSubscription.thank_text") }}</p>
                </div>
                <router-link to="/personal">
                    <p class="p-btn usable">
                        {{ $t("manageSubscription.back") }}
                    </p>
                </router-link>
            </div> -->
        </div>
    </div>
</template>

<script>
import popup from "@/components/popup.vue";
import chooseCard from "@/components/chooseCardComponent.vue";
import { mapGetters } from "vuex";
import { getPrice, changeSubscribe, operatSub } from "@/api/subscription.js";
import { feedback } from "@/api/otherSources.js";
import { fbTracking } from "@/utils/parse.js";

export default {
    components: { popup, chooseCard },
    name: "manageSubscription",
    data() {
        return {
            currentSubscription: {},
            currentNum: -1,
            devicesNum: [1, 2, 3, 4, 5, this.$t("manageSubscription.more")],
            currentSystem: "",
            currentType: -1,
            isUpdateSubscription: false,
            isShowPopup: false,
            customNum: null,
            isSuccess: false,
            isWarning: false,
            isError: false,
            isStopSub: false,
            popupText: "",
            isWaiting: false,
            priceInfo: null,
            beGetting: false,
            isChoosePayment: false,
            isLoading: false,
            currentCard: null,
            parameter: null,

            nowSystem: "",
            nowNum: 0,
            nowType: 0
        };
    },
    computed: {
        currentComponent() {
            return this.componentsList[this.currentModule];
        },
        isDisabled() {
            const res =
                this.currentSystem == this.nowSystem &&
                this.currentNum == this.nowNum &&
                this.currentType == this.nowType;
            return res;
        },
        ...mapGetters(["currentManageSub", "isPro", "systemHasLogin", "vendorInfo"]),
        type() {
            const list = [
                {
                    name: "日度",
                    id: "1"
                },
                {
                    name: this.$t("manageSubscription.monthy"),
                    id: "2"
                },
                {
                    name: this.$t("manageSubscription.annual"),
                    id: "5"
                },
                {
                    name: this.$t("manageSubscription.life_time"),
                    id: "9"
                }
            ];
            if (!this.currentSubscription.sst_ids) return [];
            const newArray = list.filter(item => this.currentSubscription.sst_ids.includes(item.id));
            return newArray;
        }
    },
    methods: {
        toUpdateSubscription() {
            this.isUpdateSubscription = !this.isUpdateSubscription;
        },
        chooseSystem(n) {
            this.currentSystem = n;
            this.$refs.options1.scrollIntoView({ behavior: "smooth" });
        },
        chooseNum(num, i) {
            const lastIndex = this.devicesNum.length - 1;
            if (i != lastIndex) {
                this.$refs.options2.scrollIntoView({ behavior: "smooth" });
                this.currentNum = num;
            } else {
                if (window.innerWidth > 550) {
                    this.isShowPopup = true;
                    return;
                }
                const inputValue = window.prompt();
                if (inputValue !== null && !isNaN(parseInt(inputValue))) {
                    this.devicesNum[lastIndex] = parseInt(inputValue);
                }
            }
        },
        updateNum() {
            this.currentNum = parseInt(this.customNum);
            this.isShowPopup = false;
            if (this.currentNum > 5) {
                this.devicesNum[this.devicesNum.length - 1] = parseInt(this.customNum);
            }
            this.calculatePrice();
            this.$refs.options2.scrollIntoView({ behavior: "smooth" });
        },
        closePopup() {
            this.isShowPopup = false;
            this.customNum = null;
        },
        // 暂停订阅弹窗
        showPopup() {
            this.isStopSub = true;
        },
        handleSubmit() {
            const popup = this.$refs.stopSubPopup;
            if (!popup.canComfirm || popup.inFeedback) return;
            popup.inFeedback = true;
            const formData = new FormData();
            const d = this.currentSubscription;
            const other = d.pay_type == 1 ? `，订单号：${d.order_no}(${d.payment_method}支付)` : "";
            formData.append("product", `${this.vendorInfo.name}会员系统`);
            formData.append("userID", d.user_id);
            formData.append("event", "dashboard");
            formData.append("eventInfo", d.id);
            formData.append("email", d.email);
            formData.append("content", popup.reason + `，产品名：${d.name}` + other);
            formData.append("os", "");
            feedback(formData)
                .then(() => {
                    this.isSuccess = true;
                    this.popupText = this.$t("manageSubscription.request_success", {
                        officialEmail: this.vendorInfo.email
                    });
                })
                .catch(() => {
                    this.isError = true;
                    this.popupText = this.$t("contact.feedback_fail");
                })
                .finally(() => {
                    this.isStopSub = false;
                    this.isRequest = false;
                    popup.selectedReason = -1;
                    popup.specificReason = "";
                    popup.inFeedback = false;
                });
            const parseInfo = {
                location: "stop_subscribe",
                name: d.name,
                email: d.email,
                userID: d.user_id,
                language: `Browser: ${navigator.language},website:${this.$i18n.locale}`
            };
            fbTracking(parseInfo, [d.name, popup.reason, d.order_no, d.payment_method, `订阅id:${d.id}`]);
        },
        stopSubTest() {
            this.isWaiting = true;
            operatSub(this.currentSubscription.order_products_id, "stop")
                .then(() => {
                    this.isWaiting = false;
                    this.isWarning = false;
                })
                .catch(err => {
                    this.isWaiting = false;
                    this.isWarning = false;
                    this.isError = true;
                    this.popupText = this.isPro
                        ? this.$t("common.error")
                        : err.response
                        ? err.response.data.message
                        : err.message;
                });
        },
        calculatePrice() {
            this.beGetting = true;
            const data = {
                ssp_id: this.currentSubscription.ssp_id,
                payment_method: this.currentSubscription.payment_method,
                sst_id: this.currentType,
                system: this.currentSystem,
                num: this.currentNum
            };
            getPrice(data)
                .then(res => {
                    this.priceInfo = res.data;
                    this.beGetting = false;
                })
                .catch(err => {
                    this.isError = true;
                    this.popupText = this.isPro
                        ? this.$t("common.error")
                        : err.response
                        ? err.response.data.message
                        : err.message;
                });
        },
        confirmPayWay() {
            this.parameter = {
                currency: this.currentSubscription.currency,
                payment_method: this.currentSubscription.payment_method,
                order_products_id: this.currentSubscription.order_products_id,
                sst_id: this.currentType,
                device_num: {
                    [this.currentSystem]: this.currentNum
                },
                final_price: this.priceInfo.final_price
            };
            this.currentCard ? (this.parameter.card_id = this.currentCard) : "";
            if (!this.isChoosePayment) {
                this.isLoading = true;
            }

            changeSubscribe(this.parameter)
                .then(res => {
                    const cardsComponent = this.$refs.cardsComponent;
                    if (this.isChoosePayment && cardsComponent.payMethod == 1) {
                        cardsComponent.googlePay(res.data, this.currentSubscription.sst_id == 9);
                        return;
                    }
                    this.successPayment();
                })
                .catch(err => {
                    this.isLoading = false;
                    this.isError = true;
                    this.popupText = this.isPro
                        ? this.$t("common.error")
                        : err.response
                        ? err.response.data.message
                        : err.message;
                });
        },
        handleChangeSubscribe() {
            if (this.priceInfo.final_price > 0) {
                this.isChoosePayment = true;
            } else {
                this.confirmPayWay();
            }
        },
        closeTip() {
            this.isSuccess = false;
            this.$navigateTo({ text: "管理订阅成功" }, "/personal");
            document.removeEventListener("click", this.closeTip);
        },
        successPayment() {
            this.isChoosePayment = false;
            this.isLoading = false;
            this.isSuccess = true;
            this.popupText = this.$t("manageSubscription.update_success");
            document.addEventListener("click", this.closeTip);
        },
        checkE(e) {
            if (!e.isTrusted) {
                this.customNum = 1;
            }
        }
    },
    mounted() {
        this.isUpdateSubscription = this.isPro ? false : true;
        if (!this.systemHasLogin) {
            this.$navigateTo({ text: "账号未登录" }, "/login");
            return;
        }
        this.currentSubscription = this.currentManageSub;
        this.currentSystem = this.nowSystem = Object.keys(this.currentManageSub.device_total_use_num)[0];
        this.currentNum = this.nowNum = parseInt(this.currentManageSub.device_total_use_num[this.nowSystem].total);
        if (this.nowNum > 5) {
            var index = this.devicesNum.length - 1;
            this.devicesNum.splice(index, 0, this.nowNum);
        }
        this.currentType = this.nowType = this.currentManageSub.sst_id;
    },
    watch: {
        isDisabled(newValue) {
            !newValue ? this.calculatePrice() : "";
        },
        currentNum() {
            !this.isDisabled ? this.calculatePrice() : "";
        },
        currentSystem() {
            !this.isDisabled ? this.calculatePrice() : "";
        },
        currentType() {
            !this.isDisabled ? this.calculatePrice() : "";
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/manageSubscription.scss";
.loading-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #6086e8;
    border-top-color: transparent;
    animation: circle infinite 0.75s linear;
}
ul {
    width: 100%;
}
.pay-card {
    list-style: none;
    width: 100%;
    display: flex;
    border-radius: 5px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    background: transparent !important;
    color: #000 !important;
    justify-content: flex-start;
    cursor: pointer;
}
</style>
