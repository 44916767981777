<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
};
</script>

<style lang="scss">
#app,
input {
    font-family: "MiSans", sans-serif;
}
body {
    width: 100%;
    height: 100%;
    input,
    p {
        line-height: 20px;
        font-size: 16px;
        color: #000;
    }
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

// 按钮加载中
.el-loading-mask {
    background-color: transparent !important;
    .el-loading-spinner {
        .circular {
            height: 30px;
            width: 30px;
            margin: 6px;
        }
        .path {
            stroke: #fff;
        }
    }
}
</style>
