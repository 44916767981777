const match = location.hostname.match(/\.[a-z]+\.[a-z]+$/);
const domain = match ? match[0] : location.hostname;

export function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
        c_name +
        "=" +
        (escape(value) +
            (expiredays == null ? "" : ";expires=" + exdate.toUTCString())) +
        ";path=/;domain=" +
        domain;
}
export function setCookieForTime(c_name, value, time) {
    // js用的毫秒，要*1000
    var exdate = new Date(time * 1000);
    exdate.setDate(exdate.getDate());
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        (time == null ? "" : ";expires=" + exdate.toUTCString()) +
        ";path=/;domain=" +
        domain;
}
export function setCookieForSecond(c_name, value, expireSeconds) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + (expireSeconds / 60) * 60 * 24);
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        (expireSeconds == null ? "" : ";expires=" + exdate.toUTCString()) +
        ";path=/;domain=" +
        domain;
}
export function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}
export function removeCookie(c_name) {
    setCookie(c_name, "", -1);
}
