var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{style:({ height: _vm.isvh ? '40px' : '48px', width: '24px' }),attrs:{"loading":"lazy","src":_vm.isvh
                ? require(`@/assets/img/icon/${
                      _vm.isText ? 'open' : 'close'
                  }-eye.svg`)
                : require(`@/assets/img/icon/dm-${
                      _vm.isText ? 'open' : 'close'
                  }-eye.svg`)},on:{"click":_vm.changeStatus}})])
}
var staticRenderFns = []

export { render, staticRenderFns }