<template>
    <div class="personal-center">
        <div class="sidebar-container" v-show="screenWidth > 992 || showMenu">
            <div class="info" v-if="userInfo">
                <img
                    loading="lazy"
                    class="profile img80"
                    :src="userInfo.avatar ? userInfo.avatar : require('@/assets/img/personal/init-profile.svg')"
                    alt="profile"
                />
                <div>
                    <p class="username">{{ userInfo.first_name }}&nbsp;{{ userInfo.last_name }}</p>
                    <p class="email">{{ userInfo.email }}</p>
                </div>
                <p
                    class="p-btn logout-btn"
                    v-if="screenWidth < 992"
                    @click="handleLogout($event)"
                    :class="aborting ? 'isLoading' : ''"
                >
                    {{ $t("dm_home.logout") }}
                </p>
            </div>
            <div class="sidebar-main">
                <p
                    class="sidebar-link"
                    :class="index == currentModule ? 'active' : ''"
                    v-for="(name, index) in componentsList"
                    :key="name"
                    @click="chooseModule(index)"
                >
                    {{ allComponentsInfo[name].title }}
                </p>
            </div>
        </div>
        <popup
            :isError="isError"
            :isSuccess="isSuccess"
            :isWarning="isWarning"
            :text="popupText"
            :isWaiting="isWaiting"
            :customConfirm="customConfirm"
        ></popup>
        <div class="component-container" ref="container">
            <p class="component-title">{{ currentComponent.title }}</p>
            <loading v-show="isRequest"></loading>
            <component
                ref="component"
                v-if="userInfo"
                v-show="!isRequest"
                :is="currentComponent.e"
                :userInfo="userInfo"
            />
        </div>
    </div>
</template>

<script>
import userInfoComponent from "@/components/personal/userInfo.vue";
import subscription from "@/components/personal/subscription.vue";
import manage from "@/components/personal/manage.vue";
import billing from "@/components/personal/billing.vue";
import contact from "@/components/personal/contact.vue";
import services from "@/components/personal/services.vue";
import subaccount from "@/components/personal/subaccount.vue";
import cart from "@/components/personal/cart.vue";
import updatePwd from "@/components/personal/updatePwd.vue";
import loading from "@/components/loading.vue";

import Popup from "@/components/popup.vue";

import { mapGetters } from "vuex";
import { getUserInfo } from "@/api/user.js";
import { jwtLogin } from "@/api/login.js";
import { setCookie } from "@/utils/operateCookie.js";
import { logout } from "@/api/login.js";
import { removeCookie } from "@/utils/operateCookie.js";
import { getPurchasedProduct, getPurchasedPlan } from "@/api/dmapi/user.js";
import { updateData } from "@/utils/parse.js";

export default {
    name: "personalCenter",
    components: {
        userInfoComponent,
        subscription,
        manage,
        billing,
        contact,
        services,
        subaccount,
        Popup,
        updatePwd,
        cart,
        loading
    },
    data() {
        return {
            userInfo: null,
            currentModule: 0,
            allComponentsInfo: {
                userInfo: {
                    e: userInfoComponent,
                    title: this.$t("psersonal.sidebarItems")[0]
                },
                subscription: {
                    e: subscription,
                    title: this.$t("psersonal.sidebarItems")[1]
                },
                manage: {
                    e: manage,
                    title: this.$t("psersonal.sidebarItems")[2]
                },
                billing: {
                    e: billing,
                    title: this.$t("psersonal.sidebarItems")[3]
                },
                contact: {
                    e: contact,
                    title: this.$t("psersonal.sidebarItems")[4]
                },
                services: {
                    e: services,
                    title: this.$t("psersonal.sidebarItems")[5]
                },
                subaccount: {
                    e: subaccount,
                    title: this.$t("psersonal.sidebarItems")[6]
                },
                updatePwd: {
                    e: updatePwd,
                    title: this.$t("psersonal.sidebarItems")[7]
                },
                cart: {
                    e: cart,
                    title: this.$t("psersonal.sidebarItems")[8]
                }
            },
            componentsList: [],

            // popup
            popupText: "",
            isWarning: false,
            isSuccess: false,
            isError: false,
            isWaiting: false,
            customConfirm: null,

            vhShowMenu: false,
            innerWidth: window.innerWidth,

            isRequest: false,
            aborting: false,

            manageSeletedInfo: {
                softwareId: "",
                softwareName: "",
                subId: ""
            },

            purchasedInfo: null
        };
    },
    computed: {
        currentComponent() {
            return this.allComponentsInfo[this.componentsList[this.currentModule]];
        },
        hasPopup() {
            return this.isSuccess || this.isWarning || this.isError;
        },
        ...mapGetters(["showMenu", "screenWidth", "systemHasLogin", "accountInfo", "isPro", "vendorInfo"])
    },
    methods: {
        chooseModule(i) {
            // 关掉弹窗
            this.isError = false;
            this.isSuccess = false;
            this.isWarning = false;

            this.currentModule = i;
            sessionStorage.setItem("currentModule", i);
            this.$store.commit("dmCommon/changeMenuStatus", false);
        },
        handleConfirm() {
            this.$refs.component.handleConfirm();
        },
        getUserInfomartion() {
            getUserInfo().then(response => {
                this.userInfo = response.data;
                this.$store.commit("user/setUserInfo", response.data);
                updateData({ email: this.userInfo.email, userID: response.data.id });
            });
        },
        handleLogout(e) {
            this.aborting = true;
            logout()
                .then(() => {
                    this.aborting = false;
                    this.$store.commit("dmCommon/changeLoginStatus", false);
                    this.$navigateTo(e, "/login");
                    sessionStorage.removeItem("email");
                    this.$store.commit("user/setUserInfo", null);
                    removeCookie("_token");
                })
                .catch(() => {})
                .finally(() => {
                    this.aborting = false;
                });
        },
        getPurchasedList() {
            const api = this.vendorInfo.planSearch ? getPurchasedPlan : getPurchasedProduct;
            api()
                .then(res => {
                    this.purchasedInfo = res.data.list;
                })
                .catch(() => {});
        }
    },
    created() {
        if (this.$route.query.token) {
            jwtLogin(this.$route.query.token)
                .then(res => {
                    setCookie("_token", res.data, 1);
                    this.$store.commit("dmCommon/changeLoginStatus", true);
                    this.accountInfo ? (this.userInfo = this.accountInfo) : this.getUserInfomartion();
                    this.$router.replace("personal");
                    this.getPurchasedList();
                })
                .catch(() => {
                    this.$navigateTo({ text: "软件登录链接验证失败" }, "/login");
                });
        } else {
            this.accountInfo ? (this.userInfo = this.accountInfo) : this.getUserInfomartion();
            this.purchasedInfo ? "" : this.getPurchasedList();
        }

        this.componentsList =
            this.isPro && this.vendorInfo.proIndexModules
                ? this.vendorInfo.proIndexModules
                : this.vendorInfo.indexModules;
    },
    mounted() {
        if (!this.systemHasLogin && !this.$route.query.token) {
            let timer = setTimeout(() => {
                this.$navigateTo({ text: "账号未登录" }, "/login");
                clearTimeout(timer);
            }, 500);
            return;
        }
        if (this.$route.hash == "#manage-devices") {
            sessionStorage.setItem(
                "currentModule",
                this.componentsList.findIndex(item => item === "manage")
            );
        }
        const preIndex = sessionStorage.getItem("currentModule");
        this.currentModule = preIndex ? preIndex : 0;
        this.$router.replace({ path: "/personal" });

        window.addEventListener("message", e => {
            const d = e.data;
            switch (d.event) {
                case "changeMenuStatus":
                    this.vhShowMenu = d.value;
                    break;
            }
        });
        window.addEventListener("resize", () => {
            this.innerWidth = window.innerWidth;
        });
    }
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/personal.scss";
</style>
