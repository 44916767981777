import { render, staticRenderFns } from "./dmHome.vue?vue&type=template&id=687e9823&scoped=true"
import script from "./dmHome.vue?vue&type=script&lang=js"
export * from "./dmHome.vue?vue&type=script&lang=js"
import style0 from "./dmHome.vue?vue&type=style&index=0&id=687e9823&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687e9823",
  null
  
)

export default component.exports