<template>
    <div class="manage-device personal-subscription">
        <div class="custom-select" :class="isSelect ? 'active' : ''">
            <p @click="isSelect = !isSelect">
                {{ currentSoftware }}<span></span>
            </p>
            <div class="select-options" v-show="isSelect">
                <p
                    v-for="(s, i) in menuItems"
                    :key="i"
                    :class="currentSoftware == s.name ? 'active' : ''"
                    @click="inquire(s.name, s.id)"
                >
                    {{ s.name }}
                </p>
            </div>
        </div>
        <div class="subscriptions-container" v-if="info.length != 0">
            <div
                class="card"
                v-for="(item, index) in info"
                :key="index"
                :class="[
                    item.is_active == 1 ? 'highlight' : '',
                    isActiveItem(index) ? 'active' : '',
                ]"
                :ref="item.is_active == 1 ? 'highlightItem' : ''"
            >
                <div class="top-container">
                    <div class="card-top">
                        <div>
                            <img
                                loading="lazy"
                                :src="item.icon"
                                alt="product logo"
                            />
                            <p class="name">{{ item.plan.plan_name }}</p>
                        </div>
                        <div>
                            <p
                                class="p-btn reset"
                                :class="
                                    getResetAllNum(item.notes) == 0
                                        ? 'disabled'
                                        : 'usable'
                                "
                                @click="
                                    resetAllCode(
                                        getResetAllNum(item.notes),
                                        item.id
                                    )
                                "
                            >
                                {{ $t("manage_devices.reset_all_code") }}
                            </p>
                            <p>
                                {{
                                    $t("manage_devices.using_device", {
                                        num1: item.used_device_count,
                                        num2: item.device_count,
                                    })
                                }}
                            </p>
                        </div>
                    </div>
                    <p
                        class="arrow-btn"
                        :class="item.used_device_count == 0 ? 'disabled' : ''"
                        @click="
                            hanedleToggle(index, item.used_device_count == 0)
                        "
                    >
                        <img src="@/assets/img/icon/arrow.svg" alt="arrow" />
                    </p>
                </div>
                <div class="card-main">
                    <div
                        class="a-device"
                        v-for="(n, index) in item.notes"
                        :key="index"
                    >
                        <div v-if="n.state == 0">
                            <img
                                loading="lazy"
                                :src="
                                    require(`@/assets/img/personal/${n.device_type}.png`)
                                "
                                alt="device logo"
                            />
                            <div>
                                <p class="attribute-name">
                                    {{ $t("manage_devices.device_name") }}:
                                    <span class="attribute-value">
                                        {{ n.device_name }}
                                    </span>
                                </p>
                                <p class="attribute-name">
                                    {{ $t("manage_devices.system") }}:
                                    <span class="attribute-value">
                                        {{ n.device_type }}
                                    </span>
                                </p>
                                <p class="attribute-name">
                                    {{ $t("manage_devices.reg_time") }}:
                                    <span class="attribute-value">
                                        {{ n.created_at }}
                                    </span>
                                </p>
                                <p class="attribute-name">
                                    {{ $t("manage_devices.residue_num") }}:
                                    <span class="attribute-value">
                                        {{ n.reset_info.remaining_times }}
                                    </span>
                                </p>
                                <div
                                    v-if="
                                        n.reset_info.date &&
                                        n.reset_info.date.length != 0
                                    "
                                    class="reset-history"
                                >
                                    <p class="attribute-name">
                                        {{
                                            $t("manage_devices.reset_history")
                                        }}:
                                    </p>
                                    <p class="attribute-value">
                                        <span
                                            v-for="(d, i) in n.reset_info.date"
                                            :key="i"
                                        >
                                            {{ d }}
                                        </span>
                                    </p>
                                </div>
                                <p
                                    class="p-btn gray reset-code-btn"
                                    :class="
                                        n.reset_info.remaining_times == 0
                                            ? 'disabled'
                                            : 'usable'
                                    "
                                    @click="
                                        resetAcode(
                                            n.reset_info.remaining_times,
                                            item.id,
                                            n.id,
                                            n.device_type,
                                            n.device_name
                                        )
                                    "
                                >
                                    {{ $t("manage_devices.reset_a_code") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDevices, resetAllLicense } from "@/api/dmapi/user.js";
import { mapGetters } from "vuex";

export default {
    name: "subscriptionComponent",
    data() {
        return {
            info: [],
            currentLicenseId: null,
            currentNoteId: "",
            currentType: "",
            totalCount: 0,
            // currentPage: 1,
            status: 0,
            currentName: "",
            isSelect: false,
            actvieItem: [],
        };
    },
    computed: {
        ...mapGetters(["isPro", "vendorInfo"]),
        menuItems() {
            const arr = this.$parent.purchasedInfo
                ? this.$parent.purchasedInfo.slice()
                : [];
            arr.unshift({
                name: this.$t("manage_devices.all"),
            });
            return arr;
        },
        currentSoftware() {
            const name = this.$parent.manageSeletedInfo?.softwareName;
            return name ? name : this.$t("manage_devices.all");
        },
    },
    methods: {
        resetAcode(num, licenseId, noteId, type, name) {
            if (num == 0) return;
            this.status = 0;
            this.currentLicenseId = licenseId;
            this.currentNoteId = noteId;
            this.currentType = type;
            this.$parent.popupText =
                this.$t("manage_devices.num_tip", { num: num }) +
                this.$t("manage_devices.a_warning_text", {
                    name: name,
                });
            this.$parent.isWarning = true;
            this.currentName = name;
        },
        handleConfirm() {
            this.resetLicense();
            this.$parent.isWaiting = true;
        },
        resetAllCode(num, id) {
            if (num == 0) return;
            this.status = 1;
            this.currentType = "";
            this.currentNoteId = "";
            this.currentLicenseId = id;
            this.$parent.popupText =
                this.$t("manage_devices.num_tip", { num: num }) +
                this.$t("manage_devices.all_warning_text");
            this.$parent.isWarning = true;
        },
        resetLicense() {
            resetAllLicense(
                this.currentLicenseId,
                this.currentNoteId,
                this.currentType
            )
                .then((res) => {
                    this.$parent.isWarning = false;
                    this.$parent.isWaiting = false;

                    const status = res.data.status;
                    const code = res.data.error_code;
                    if (status == 500) {
                        this.$parent.isError = true;
                        switch (code) {
                            case 9:
                                this.$parent.popupText = this.$t(
                                    "manage_devices.reset_fail_9"
                                );
                                break;
                            case 10:
                                this.$parent.popupText = this.$t(
                                    "manage_devices.reset_fail_10"
                                );
                                break;
                            default:
                                this.$parent.popupText = res.data.message;
                        }
                    } else if (status == 200) {
                        this.$parent.isSuccess = true;
                        this.$parent.popupText =
                            this.status == 1
                                ? this.$t("manage_devices.all_reset_success")
                                : this.$t("manage_devices.a_reset_success", {
                                      name: this.currentName,
                                  });
                        document.addEventListener("click", this.refreshInfo);
                    }
                })
                .catch((err) => {
                    this.$parent.isWarning = false;
                    this.$parent.isWaiting = false;
                    this.$parent.isError = true;
                    this.$parent.popupText = this.isPro
                        ? this.$t("common.error")
                        : err.response
                        ? err.response.data.message
                        : err.message;
                });
        },
        getInfo() {
            this.$parent.isRequest = true;
            getDevices(
                this.$parent.manageSeletedInfo?.softwareId,
                this.$parent.manageSeletedInfo?.subId
            )
                .then((res) => {
                    this.info = res.data.list;
                    this.totalCount = res.data.total;
                    this.$parent.isRequest = false;
                    setTimeout(() => {
                        const highlightItem = this.$refs.highlightItem;
                        if (highlightItem && highlightItem.length != 0) {
                            highlightItem[0].scrollIntoView({
                                behavior: "smooth",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$parent.isRequest = false;
                });
        },
        inquire(name, id) {
            this.$parent.manageSeletedInfo.softwareName = name;
            this.$parent.manageSeletedInfo.softwareId = id || "";
            this.$parent.manageSeletedInfo.subId = "";
            this.isSelect = false;
            this.getInfo();
        },
        refreshInfo() {
            this.getInfo();
            document.removeEventListener("click", this.refreshInfo);
        },
        hanedleToggle(i, bool) {
            if (bool) return;
            const index = this.actvieItem.indexOf(i);
            index != -1
                ? this.actvieItem.splice(index, 1)
                : this.actvieItem.push(i);
        },
        isActiveItem(i) {
            return this.actvieItem.some((index) => index == i);
        },
        getResetAllNum(notes) {
            const validArr = notes.filter((item) => item.state == 0);
            if (validArr.length == 0) return 0;
            const arr = [];
            validArr.forEach((itm) => {
                arr.push(itm.reset_info.remaining_times);
            });
            return Math.min(...arr);
        },
    },
    created() {
        this.getInfo();
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/personal.scss";
</style>
