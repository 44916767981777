const getters = {
    // common
    vendorInfo: (state) => state.common.vendorInfo,
    screenWidth: (state) => state.common.screenWidth,
    isPro: (state) => state.common.isPro,
    deviceName: (state) => state.common.deviceName,
    //dumpMedia
    systemHasLogin: (state) => state.dmCommon.systemHasLogin,
    showMenu: (state) => state.dmCommon.showMenu,

    //user
    accountInfo: (state) => state.user.accountInfo,

    //subscription
    currentManageSub: (state) => state.subscription.currentManageSub,
};
export default getters;
