export default {
    choose_lang: "English",
    common: {
        email: "Email",
        pwd: "Password",
        send: "Send",
        format_error: "格式错误！",
        error: "Error",
        now: "(now)",
        vip_system: "Membership System",
        first_name: "First name",
        last_name: "Last name",
        tip_first_name: "first name",
        tip_last_name: "last name",
    },
    login: {
        login: "Login",
        title: "Welcome back",
        login_tip: "Please log in to your account",
        save_pwd: "Save password",
        free_login: "Automatic login within 15 days",
        forgot_pwd: "Forgot password",
        no_account: "Don't have an account?",
        create_account: "Sign Up",
        email_format_error: "The format of your email address is not correct.",
        pwd_error: "Incorrect password!",
        account_no_exist: "This account does not exist!",
        login_success: "Login successful",
    },
    register: {
        new_account: "Create new account",
        reg_tip: "Fill in this form to create a new account",
        confirm_pwd: "Password confirmation",
        reg_account: "Create account",
        terms_and_policy:
            "By signing up, you accept and agree to our <a class='term'>{term}</a> and <a class='policy'>{policy}</a>.",
        term: "Terms of Use",
        policy: "Privacy Policy",
        have_account: "Already have an account? ",
        login: "Log in",
        name_limit: "The {fieldName} must not exceed {max} characters!",
        email_invalid: "Invalid Email",
        email_no_exist: "This email does not exist",
        pwd_limit:
            "Password must be 8-20 characters in length, without special symbols, and must include at least 1 number and 1 letter (case insensitive).",
        pwd_different: "Passwords do not match!",
        reg_fail_server:
            "Account creation failed! The server is down for maintenance. Please try again later.",
        reg_fail_net:
            "Account creation failed! Please check your internet connection and try again.",
        reg_success: "Account created successfully ",
        check_tip: "I accept the terms & conditions.",
        please_accept: "Please check to accept the terms and conditions.",
        recover_pwd: "Recover password",
        reset: "Reset",
        account_presence: "This email address already exists, please log in!",
        receive_newsletter: "Subscribe and receive our newsletter.",
    },
    forget: {
        title: "Reset your password",
        tip: "Enter your email address and we'll send password reset instructions.",
        backToLogin: "Return to<a class='loginLink'>{login}</a>",
        login: "log in",
        forget_success: "We've sent you an email to reset your password!",
        send_fail_server:
            "Failed to send! The server is down for maintenance. Please try again later.",
        send_fail_net:
            "Failed to send! Please check your internet connection and try again.",
    },
    update: {
        title: "Change your password",
        tip: "Please enter your new password",
        new_pwd: "New password",
        submit: "Send",
        update_fail_server:
            "Failed to reset! The server is down for maintenance. Please try again later.",
        update_fail_net:
            "Failed to reset! Please check your internet connection and try again.",
        update_success: "Password reset successfully",
    },
    psersonal: {
        sidebarItems: [
            "Personal information",
            "Subscription",
            "Manage devices",
            "Billing",
            "Contact Support",
            "Value-added services",
            "Sub-account functionality",
            "Change password",
            "Shopping cart",
        ],
    },
    userInfoModule: {
        edit: "edit",
        name: "Name",
        update: "Update",
        reg_time: "Registration Time",
        updatePwd: {
            forgot: "Forgot",
            current_pwd: "Current password",
            current_pwd_p: "Enter current password",
            new_pwd_p: "Enter new password",
            confirm_pwd_p: "Repeat new password",
            update_btn: "Update password",
        },
        update_success: "Name modification successful",
        pwd_update_success:
            "Password changed successfully, please log in again.",
        profile_update_success: "Avatar change successful",
        destroy: "Delete account",
        destroy_tip: "Are you sure you want to delete the account?",
        destroy_tip2:
            "This operation will permanently delete your account and related data. The features activated under your account will be unavailable after successful logout. Please proceed with caution!",
        destroy_success: "Account log-out successful!",
        pwd_wrong: "Incorrect user password!",
        have_sub:
            "You cannot cancel a subscription with a normal renewal status. Please pause the subscription first!",
    },
    subscription: {
        stop_text:
            "You have paused the membership subscription plan for {plan}",
        cancel_text: "Your {plan}'s membership subscription has been canceled.",
        canceling:
            "Your {plan} membership subscription plan is currently being canceled.",
        renew: "Resume subscription",
        renew_sucess: "Subscription resumed successfully!",
        expire_date: "Expire date",
        status_text: "Subscription status",
        leave: "Leave",
        purchase_date: "Purchase time",
        end_date: "Next payment",
        price: "Price",
        num: "Supported devices",
        account_status: "Account status",
        no_subscription: "You currently have no subscriptions, ",
        recommend: "perhaps you might be interested in the following content",
        learn_more: "Learn More",
        free_download: "Free Download",
        trial_limit: "Trial limitations",
        blocked_on: "Blocked on",
        trial_start: "Trial start time",
        start_subscription: "Start subscription",
        is_trial: "You are currently in trial status",
        manage_btn: "Manage subscription",
        change_payment: "Change Payment details",
        payment_detail: "Payment details",
        leave_tip: "Are you sure you want to leave the family plan?",
        leave_success: "You have successfully left the family plan!",
        singular_device_num:
            "{num1} {type} devices available, {num2} activated",
        plural_device_num: "{num1} {type} devices available, {num2} activated",
        see_detail: "View Details",
        active: "Active",
        pause: "Pause",
        overdue: "(Overdue)",
        update_payment_success: "Payment method updated successfully",
        being_processed: "Processing",
    },
    subaccount: {
        invite: "Invite people to join",
        send_invite: "Send invite",
        manage_title: "Manage Family menbers",
        remove: "Remove",
        send_success:
            "You have successfully sent an invitation email, pending confirmation from the other party!",
        remove_user: "Are you sure you want to remove the user {name}?",
        all_product: "All products",
    },
    billing: {
        excl_tax: "(excl. sales tax)",
        view_receipt: "View receipt",
        all_time: "All time",
        refunded: "Refunded",
    },
    manage_devices: {
        reset_all_code: "Reset all devices",
        reset_a_code: "Reset current device",
        all_reset_success: "You have successfully reset all devices!",
        a_reset_success: "You have successfully reset {name}!",
        num_tip:
            "There is only {num} remaining reset opportunity for this year.",
        a_warning_text: "Are you sure you want to reset {name}?",
        all_warning_text: "Are you sure you want to reset all devices?",
        device_name: "Device name",
        system: "System",
        reg_time: "Registration Time",
        using_device: "You’re using {num1} out of {num2} devices",
        all: "All",
        residue_num: "Remaining reset attempts",
        reset_history: "Reset history time",
        reset_fail_9: "Reset failed, no matching data to reset",
        reset_fail_10:
            "Reset failed, exceeded the limit. If you have any questions, please contact support.",
    },
    contact: {
        support_center: "Support Center",
        support_tip: "Find out what you need here",
        feedback: "Feedback",
        submit: "Submit",
        feedback_fail: "Failed to submit feedback, please try again later！",
        feedback_success: "Thanks for your feedback!",
        enter_content: "Please enter feedback content",
        content_placeholder: "Please leave your suggestions or questions here.",
        select: "Select",
    },

    dm_home: {
        logout: "Log out",
    },
    dm_login: {
        login_desc: "Welcome back, please login to your account.",
        captcha_pl: "Please enter the verification code",
        or: "or",
        reg: "Register",
        enter_tip: "Please enter your {fieldName}",
        enter_email: "Please enter your email",
        enter_pwd: "Please enter your password",
        captcha_limit: "Verification code length is 4",
        login_expired:
            "To protect the security of your account, you need to re-enter your password.",
        account_pwd_err: "Login account or password error!",
    },
    dm_reg: {
        create: "Create Account",
        fill_form: "Fill out the form below to create a new account.",
    },
    dm_reset_pwd: {
        reset: "Recover your password",
        reset_pwd: "Reset Pssword",
        reset_tip:
            "Please enter your email address and we'll send you instructions on how to reset your password.",
        back_login: "Back to login",
        email_error: "Email does not exist",
        reset_success: "You have successfully reset your password.",
        email_had_sent:
            "The password reset email has been sent to the mailbox, please reset your password!",
        no_sign_up:
            "There is no account yet for this email address, please sign up for an account.",
    },
    manageSubscription: {
        title: "Manage subscription",
        desc: "Your current plan is:",
        change_methods: "Change subscription method",
        choose_system_tip: "Please select the device system you need",
        choose_num_tip: "Please select the number of devices you need",
        enter_num: "Please enter the number of devices you need",
        click_enter: "Click to enter",
        confirm: "Confirm",
        cancel: "Cancel",
        choose_card_tip: "Please select the payment card number",
        add_card: "Add card number",
        choose_way_tip: "Please select the subscription method you need",
        discounts_tip: "You have been discounted",
        total: "Total:",
        excl_tax: "(excl. sales tax)",
        be_counting: "Calculating the price",

        end_tip: "Your expiration date is:",
        thank_text:
            "Once again, heartfelt thanks for your continued support. We look forward to your next visit!",
        back: "Back to Subscription",
        update_success: "Subscription change successful",
        pay_btn: "Pay now",
        monthy: "Monthly",
        annual: "Annual",
        life_time: "Lifetime",
        more: "More",
        pay_tip:
            "Change payment method: The platform will deduct 0.01 from the new card, and upon successful deduction, it can be linked. Subsequently, the amount will be refunded to the original payment method.",
        is_add: "Add New Card",
        is_set: "Set New Card",
        cancel_sub: "Pause Subscription",
        tip: "If you pause your subscription now, you can still use {name} until the end of the current billing cycle.",
        cancel_success: "Subscription paused successfully.",
        cancel_text:
            "You have successfully paused the membership subscription plan for {productName}. However, you can continue using {productName} until the last day of your subscription.",
        are_you_sure: "Are you sure you want to pause the subscription?",
        request_success: `Your request has been successfully sent; our customer service center will process it within 48 hours. Please check your email from {officialEmail}.`,
    },
    receipt: {
        title: "Receipt for {name}",
        back: "Back",
        receipt_text: "Receipt",
        amount_paid: "Amount Paid",
        date: "Receipt Date",
        pay_method: "Payment Method",
        discount: "Discount",
        discount_tip: "Item Listed are included in the price",
        contact_tip: "If you need help with {brand}, please contact us",
        thank: "Thank You",
        item: "Items & Description",
        qty: "Quantity",
        price: "Price",
        amount: "Gross Withdrawal Amount(s) ",
        subtotal: "Subtotal",
        total: "TOTAL",
    },
    order_complete: {
        title: "Order Complete!",
        desc: "Thank you for your purchase! A delivery email will be sent to Customer Email shortly.",
        order_id: "Your order ID",
        price: "Order Total",
        name: "Product Name",
        delivery: "Delivery",
        electronic: "Electronic",
        unit_price: "Unit Price",
        quantity: "Quantity",
        final: "Discounted Price",
        detail: "Order Details",
        license_email: "Licensed e-mail",
        license_tip: "License key will be include in the delivery email.",
        contact_text:
            "If you did not receive the delivery email, please contact customer support:",
    },
    popup: {
        ok: "Ok",
        cancel: "Cancel",
        confirm: "Confirm",
        options: ["Software Issues", "Price", " Other"],
    },
    add_new_card: {
        please_fill: "Please complete the information",
    },
};
