const state = {
    accountInfo: null,
};

const mutations = {
    setUserInfo: (state, info) => {
        state.accountInfo = info;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
