<template>
    <div ref="vhPage" class="vh-page" :style="`height:${containerHeight};`">
        <router-view></router-view>
    </div>
</template>

<script>
import { logout } from "@/api/login.js";
import { showMessage } from "@/utils/message.js";
import { removeCookie } from "@/utils/operateCookie.js";
import { mapGetters } from "vuex";

export default {
    name: "vhPage",
    data() {
        return {
            containerHeight: "",
        };
    },
    computed: {
        whiteBg() {
            const href = window.location.href;
            return href.includes("personal") || href.includes("order-complete");
        },
        ...mapGetters(["isPro"]),
    },
    mounted() {
        this.whiteBg ? this.$refs.vhPage.classList.add("persoanl") : "";

        const bodyHeight = document.body.scrollHeight;
        const htmlHeight = document.documentElement.scrollHeight;
        if (bodyHeight < htmlHeight) {
            let timer = setTimeout(() => {
                this.containerHeight = "100vh";
                clearTimeout(timer);
            }, 200);
        }

        window.addEventListener("message", (e) => {
            if (e.origin == process.env.VUE_APP_PARENT) {
                const d = e.data;
                switch (d.event) {
                    case "handleLogout":
                        this.handleLogOut();
                }
            }
        });

        window.addEventListener("resize", () => {
            this.$store.commit("common/updateWidth", window.innerWidth);
        });
    },

    methods: {
        handleLogOut() {
            logout()
                .then(() => {
                    const data = {
                        identifier: "logoutSuccess",
                    };
                    window.parent.postMessage(data, process.env.VUE_APP_PARENT);
                    sessionStorage.setItem("vhHasLogin", false);
                    removeCookie("_token");
                })
                .catch((err) =>
                    showMessage(
                        this.isPro
                            ? this.$t("common.error")
                            : err.response
                            ? err.response.data.message
                            : err.message,
                        "error"
                    )
                );
        },
        changePage(name) {
            const data = {
                identifier: "changeHref",
                page: name,
            };
            window.parent.postMessage(data, process.env.VUE_APP_PARENT);
        },
    },
};
</script>

<style scoped lang="scss">
.vh-page {
    background: url(@/assets/img/login/login-bg.png) no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.persoanl {
    background: #fff;
    align-items: flex-start;
}
</style>
